import React from 'react';
import { Button } from '@mui/material';
import AuthPageLayout from './common/components/AuthPageLayout';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      redirect: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    // Redirect to home page after  seconds
    this.timerId = setTimeout(() => {
      this.setState({ redirect: true });
    }, 1000000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  render() {
    const { error, redirect } = this.state;
    if (redirect && error) {
      window.location = '/';
      return null;
    }

    if (error) {
      return (
        <AuthPageLayout>
          <div className="not-found-page">
            <p className="error-text">500</p>
            <span>Oops! Something went wrong.</span>
            <p>We&apos;re sorry, but there was an unexpected error on our end. Our team has been notified and is working to fix it. Please try again later.</p>
            <Button className="back-btn" onClick={() => window.location = '/'}>Back to Home</Button>
          </div>
        </AuthPageLayout>
      );
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
