import React from 'react';
import Pagination from '@mui/material/Pagination';
import { Stack } from '@mui/material';

const SettingsPagination = ({
  items = [],
  perPage = 10,
  currentPage = 1,
  setCurrentPage = () => {},

}) => {
  const getStartValue = () => (currentPage === 1 ? 1 : ((currentPage - 1) * perPage) + 1);
  const getEndValue = () => {
    const endValue = currentPage * perPage;
    if (endValue > items.length) {
      return items.length;
    }
    return endValue;
  };
  return (
    <div className="settings-pagination">
      <p>{`${getStartValue()} - ${getEndValue()} of ${items.length} items`}</p>
      <Stack spacing={2}>
        <Pagination
          count={Math.ceil((items.length / perPage))}
          size="small"
          page={currentPage}
          onChange={(_, value) => setCurrentPage(value)}
        />
      </Stack>
    </div>
  );
};
export default SettingsPagination;
