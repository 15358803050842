import { createSlice } from '@reduxjs/toolkit';
import batteryPercentage from '../common/util/batteryPercentage';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    server: null,
    user: null,
    socket: null,
    mapFullScreen: false,
    positions: {},
    history: {},
    userEvents: [],
    subscriptionItems: [],
    subscriptionItemsNonRecursive: [],
    authToken: null,
    products: [],
  },
  reducers: {
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateAuthToken(state, action) {
      state.authToken = action.payload;
    },
    updateSocket(state, action) {
      state.socket = action.payload;
    },
    updateMapFullScreen(state, action) {
      state.mapFullScreen = action.payload;
    },
    updateUserEvents(state, action) {
      state.userEvents = action.payload;
    },
    updateSubscriptionItems(state, action) {
      state.subscriptionItems = action.payload;
    },
    updateProducts(state, action) {
      state.products = action.payload;
    },
    updateSubscriptionItemsNonRecursive(state, action) {
      state.subscriptionItemsNonRecursive = action.payload;
    },
    updatePositions(state, action) {
      const liveRoutes = state.user.attributes.mapLiveRoutes || state.server.attributes.mapLiveRoutes || 'none';
      const liveRoutesLimit = state.user.attributes['web.liveRouteLength'] || state.user.attributes['web.liveRouteLength'] || 10;
      action.payload.forEach((position) => {
        state.positions[position.deviceId] = {
          ...position,
          attributes: {
            ...position.attributes,
            ...(position?.attributes?.batteryLevel && {
              batteryLevel: position.attributes.batteryLevel,
            }),
            ...(position?.attributes?.battery && {
              batteryLevel: batteryPercentage(position.attributes.battery),
            }),
          },
        };
        if (liveRoutes !== 'none') {
          const route = state.history[position.deviceId] || [];
          const last = route.at(-1);
          if (!last || (last[0] !== position.longitude && last[1] !== position.latitude)) {
            state.history[position.deviceId] = [...route.slice(1 - liveRoutesLimit), [position.longitude, position.latitude]];
          }
        } else {
          state.history = {};
        }
      });
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
