import { useEffect } from 'react';

const HubSpotScript = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the attributes for the script tag
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    script.src = '//js.hs-scripts.com/22082418.js?businessUnitId=1301104';

    // Append the script element to the <head> element
    document.head.appendChild(script);

    // Cleanup function to remove the script tag when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // Return null since this component doesn't render anything
  return null;
};

export default HubSpotScript;
