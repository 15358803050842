import React, { useEffect, useState } from 'react';
import {
  Button, IconButton, Input, Modal,
  TextField,
} from '@mui/material';
import customFetch from '../../../../common/util/customFetch';
import CircleSpinner from '../../../../CircleSpinner/CircleSpinner';

const CategoryModal = ({
  openCategory = false,
  handleClose = () => {},
  categories = [],
  setAlertMessage = () => {},
  setAlertHeaderMessage = () => {},
  setShowAlertMessage = () => {},
}) => {
  const [errors, setErrors] = useState({ category: null });
  const [showSpinner, setShowSpinner] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [categoryToSave, setCategoryToSave] = useState({
    id: null,
    name: null,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  useEffect(() => {
    const filteredData = categories.filter((category) => (
      search !== '' ? category.name.toLowerCase().includes(search.toLowerCase()) : true
    ));
    setFilteredCategories(filteredData);
  }, [search, categories]);

  const checkUniqueCategory = async (value) => {
    const url = `/api/categories/checkUniqueCategory?name=${value}&id=${categoryToSave?.id ? categoryToSave?.id : 0}`;
    const response = await customFetch(url);
    if (response.ok) {
      const text = await response.text();
      return text === 'no exist';
    }
    return false;
  };
  const checkCategoryValidation = async (value) => {
    let validate = true;
    if (!value) {
      setErrors({ category: 'Category name is required' });
      validate = false;
    } else if (value.length > 50) {
      setErrors({ category: 'Category name is too long' });
      validate = false;
    } else if (validate) {
      const unique = await checkUniqueCategory(value);
      if (!unique) {
        setErrors({ category: 'Category name already exists' });
        validate = false;
      } else {
        setErrors({ category: null });
        validate = true;
      }
    } else {
      setErrors({ category: null });
      validate = true;
    }
    return validate;
  };

  const handleSaveCategory = async () => {
    if (await checkCategoryValidation(categoryToSave.name)) {
      setShowSpinner(true);
      const url = categoryToSave?.id ? `/api/categories/${categoryToSave?.id}` : '/api/categories';
      const response = await customFetch(url, {
        method: categoryToSave?.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...categoryToSave, updatedAt: new Date() }),
      });
      const title = categoryToSave?.id ? 'Update' : 'Create';
      const message = (
        <span>
          Your Category
          {/* {' '}
          <span style={{ color: '#1A74E2' }}>{categoryToSave.name}</span> */}
          {' '}
          has been
          {' '}
          {`${title.toLowerCase()}d`}
          {' '}
          successfully.
        </span>
      );
      const alertHeaderMessage = `Category ${title}d Successfully`;
      if (response.ok) {
        setAlertHeaderMessage(alertHeaderMessage);
        setAlertMessage(message);
        setShowAlertMessage(true);
        setShowSpinner(false);
        handleClose();
      }
    }
  };

  const handleDeoleteCategory = async (category) => {
    const response = await customFetch(`/api/categories/${category.id}`, { method: 'DELETE' });
    const title = 'Delete';
    const message = (
      <span>
        Your Category
        {/* {' '}
        <span style={{ color: '#1A74E2' }}>{category.name}</span> */}
        {' '}
        has been
        {' '}
        {`${title.toLowerCase()}d`}
        {' '}
        successfully.
      </span>
    );
    const alertHeaderMessage = `Category ${title}d Successfully`;
    if (response.ok) {
      setAlertHeaderMessage(alertHeaderMessage);
      setAlertMessage(message);
      setShowAlertMessage(true);
      handleClose();
    }
  };

  return (
    <Modal
      open={openCategory}
      onClose={handleClose}
      classes={{ root: 'center-with-flex' }}
    >
      <div
        className="faq-category-modal"
      >
        <div className="modal-header">
          <p>
            Categories
          </p>
          <IconButton
            className="cross-btn"
            onClick={handleClose}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        </div>
        <div className="modal-body">
          <div className="inner-content">
            <div className="search-header">
              <div className="searchbar">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 17.3349L13.2477 13.1194M15.3503 8.10122C15.3532 9.97355 14.5992 11.7738 13.2477 13.1226C12.5919 13.7801 11.8044 14.3033 10.9326 14.6608C10.0608 15.0182 9.12288 15.2024 8.17504 15.2024C7.22719 15.2024 6.28899 15.0182 5.41719 14.6608C4.5454 14.3033 3.75794 13.7801 3.10207 13.1226C1.75321 11.7722 1 9.97296 1 8.10122C1 6.22948 1.75321 4.43024 3.10207 3.07983C3.75794 2.42233 4.5454 1.89912 5.41719 1.54169C6.28899 1.18427 7.22719 1 8.17504 1C9.12288 1 10.0608 1.18427 10.9326 1.54169C11.8044 1.89912 12.5919 2.42233 13.2477 3.07983C14.5992 4.42862 15.3532 6.22889 15.3503 8.10122Z" stroke="#BDC9E2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>

            </div>
            <ul className="category-list">
              {filteredCategories.map((category) => (
                <li key={category.id}>
                  <Input readOnly value={category.name} />
                  <div className="action-btn">
                    <IconButton onClick={() => { setCategoryToSave(category); }}>
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1394 2.43659C13.4442 2.64159 13.6957 2.91639 13.8729 3.23814C14.0502 3.5599 14.148 3.91931 14.1584 4.2865C14.1687 4.6537 14.0913 5.01805 13.9324 5.34928C13.7736 5.6805 13.538 5.96903 13.2452 6.19086L7.4293 12.3414C7.15739 12.607 6.81647 12.7911 6.44522 12.8728L4.16461 13.2627C3.98871 13.3065 3.80463 13.3053 3.62929 13.2594C3.45395 13.2134 3.29299 13.1241 3.16119 12.9996C3.0294 12.8752 2.931 12.7196 2.87506 12.5472C2.81911 12.3748 2.80741 12.1911 2.84102 12.0129L3.10303 9.71756C3.1697 9.34428 3.33346 8.99512 3.57785 8.70519L9.39377 2.55468C9.59819 2.25103 9.87187 2.00033 10.1922 1.82326C10.5126 1.6462 10.8705 1.54784 11.2364 1.5363C11.6022 1.52477 11.9656 1.60039 12.2965 1.75692C12.6274 1.91345 12.9163 2.14642 13.1394 2.43659Z" stroke="#8899B4" strokeWidth="1.30867" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.2839 7.23051C11.3273 7.13578 10.4291 6.72609 9.73052 6.06576C9.03189 5.40542 8.57228 4.53181 8.42383 3.58203" stroke="#8899B4" strokeWidth="1.30867" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.125 15.5859H14.875" stroke="#8899B4" strokeWidth="1.30867" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </IconButton>
                    <IconButton onClick={() => handleDeoleteCategory(category)}>
                      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0867 3.99865C11.6656 3.75165 9.23063 3.625 6.80256 3.625C5.36037 3.62519 3.91907 3.70013 2.48421 3.84955L1 3.99955" stroke="#8899B4" strokeWidth="1.12172" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.00195 3.24469L5.16177 2.26475C5.27876 1.55428 5.36606 1.02344 6.5945 1.02344H8.49869C9.728 1.02344 9.82234 1.58391 9.93148 2.27283L10.0912 3.24469" stroke="#8899B4" strokeWidth="1.12172" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12.5227 6.36418L12.0504 13.8947C11.9701 15.0686 11.9046 15.9812 9.8764 15.9812H5.20884C3.18065 15.9812 3.11341 15.0686 3.03571 13.8947L2.5625 6.36328" stroke="#8899B4" strokeWidth="1.12172" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </IconButton>
                  </div>
                </li>
              ))}
            </ul>
            <div className="modal-footer">
              <div className="add-new-category">
                <TextField
                  label="Category"
                  error={!!errors.category}
                  helperText={errors.category ? errors.category : ''}
                  placeholder="Add New Category"
                  className="form-textfield"
                  required
                  onChange={(e) => {
                    setCategoryToSave({ ...categoryToSave, name: e.target.value });
                  }}
                  value={categoryToSave.name}
                  onBlur={(e) => checkCategoryValidation(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                {showSpinner ? (
                  <CircleSpinner />
                ) : (
                  <Button
                    disabled={!categoryToSave.name || errors.category}
                    className="btn btn--contained"
                    onClick={handleSaveCategory}
                  >
                    {categoryToSave.id ? 'Update' : 'Add'}
                  </Button>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
