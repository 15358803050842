import React from 'react';
import ContactItem from './ContactItem';

import callIcon from '../../../resources/images/call-icon.svg';
import emailIcon from '../../../resources/images/mail-icon.svg';

const Contact = () => (
  <div className="contact">
    <div className="contact__info">
      <ContactItem icon={callIcon} title="Have a question?" desc="+1-888-231-7337" />
      <ContactItem icon={emailIcon} title="Contact us at" desc="support@takeapeeq.com" />
    </div>
    <p className="contact__description">
      Track with Precision. Secure with Confidence.
      Have Peace of Mind.
    </p>
  </div>
);

export default Contact;
