export default (stringLength = 20) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const timestamp = performance.now().toString(36).replace('.', '');

  // Calculate the length of the random string part
  const randomStringLength = stringLength - timestamp.length;
  let randomString = '';

  // Generate the random part of the string
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < randomStringLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  // Combine the random string with the timestamp
  const uniqueString = randomString + timestamp;

  // Ensure the string is exactly stringLength long
  return uniqueString.slice(0, stringLength);
};
