import React from 'react';

import { Link } from 'react-router-dom';
// import logoImage from '../../../resources/images/traccar-logo.svg';

const Logo = () => (
  <Link className="footer-logo" to="/">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="21" viewBox="0 0 60 21" fill="none">
      <path d="M12.7331 6.88565C12.7331 9.86942 11.0998 12.6106 6.82221 12.6106C5.89231 12.6106 4.85729 12.4812 3.63629 12.1982V16.8073C3.63629 17.8181 0.604004 17.8423 0.604004 16.8073V2.2685C0.604004 1.62161 2.36677 0.89386 5.96508 0.89386C10.2426 0.901946 12.7331 3.0205 12.7331 6.88565ZM9.64426 6.8614C9.64426 4.68624 8.399 3.52184 6.0136 3.52184C5.21308 3.52184 4.38021 3.62696 3.6282 3.86146V9.79665C4.66322 10.0554 5.54461 10.1848 6.27235 10.1848C8.76287 10.1767 9.64426 8.74546 9.64426 6.8614Z" fill="#021021" />
      <path d="M26.5182 17.4055H16.7421C16.3539 17.4055 16.168 16.8314 16.168 16.2653V2.17127C16.168 1.59716 16.3782 1.07965 16.7421 1.07965H26.7446C27.9656 1.07965 26.6395 3.74806 25.4265 3.74806H19.2083V7.81537H24.6988C25.7096 7.81537 25.7096 10.4838 24.6988 10.4838H19.176V14.7371H26.5101C27.5532 14.7371 27.5532 17.4055 26.5182 17.4055Z" fill="#021021" />
      <path d="M41.1038 17.4055H31.3277C30.9396 17.4055 30.7617 16.8314 30.7617 16.2653V2.17127C30.7617 1.59716 30.972 1.07965 31.3277 1.07965H41.3302C42.5512 1.07965 41.2251 3.74806 40.0122 3.74806H33.794V7.81537H39.2845C40.2952 7.81537 40.2952 10.4838 39.2845 10.4838H33.7617V14.7371H41.0957C42.1389 14.7371 42.1389 17.4055 41.1038 17.4055Z" fill="#021021" />
      <path d="M55.6406 20.5672C54.735 19.7909 53.9829 18.5699 53.5382 17.4298C52.9641 17.5349 52.3496 17.5834 51.6946 17.5834C46.8995 17.5834 44.0532 14.4703 44.0532 9.23855C44.0532 3.97451 46.9076 0.869446 51.6946 0.869446C56.5382 0.869446 59.3925 3.98259 59.3925 9.23855C59.3925 12.7075 58.1473 15.2223 55.8913 16.5484C56.4896 17.7128 57.4681 18.7478 58.5839 19.5807C59.7322 20.3569 56.3683 21.2868 55.6406 20.5672ZM51.7027 14.915C54.549 14.915 56.2147 12.8126 56.2147 9.23855C56.2147 5.64024 54.5571 3.53786 51.7027 3.53786C48.8806 3.53786 47.2472 5.64024 47.2472 9.23855C47.2472 12.8207 48.8806 14.915 51.7027 14.915Z" fill="#021021" />
      <path d="M12.7331 6.88565C12.7331 9.86942 11.0998 12.6106 6.82221 12.6106C5.89231 12.6106 4.85729 12.4812 3.63629 12.1982V16.8073C3.63629 17.8181 0.604004 17.8423 0.604004 16.8073V2.2685C0.604004 1.62161 2.36677 0.89386 5.96508 0.89386C10.2426 0.901946 12.7331 3.0205 12.7331 6.88565ZM9.64426 6.8614C9.64426 4.68624 8.399 3.52184 6.0136 3.52184C5.21308 3.52184 4.38021 3.62696 3.6282 3.86146V9.79665C4.66322 10.0554 5.54461 10.1848 6.27235 10.1848C8.76287 10.1767 9.64426 8.74546 9.64426 6.8614Z" fill="white" />
      <path d="M5.96508 0.982716C8.08364 0.982716 9.74129 1.49214 10.8976 2.51099C12.062 3.52984 12.6442 5.00151 12.6442 6.89365C12.6442 8.48661 12.1833 9.84507 11.31 10.8235C10.2911 11.9636 8.77904 12.5458 6.81412 12.5458C5.86805 12.5458 4.83303 12.4084 3.64437 12.1334L3.54734 12.1092V12.2143V16.8234C3.54734 17.2762 2.81959 17.5107 2.09993 17.5107C1.71988 17.5107 1.36409 17.446 1.09725 17.3247C0.903187 17.2358 0.676778 17.0821 0.676778 16.8234V2.26841C0.676778 1.96922 1.15386 1.65386 1.94629 1.41937C2.6417 1.21721 3.88696 0.982716 5.96508 0.982716ZM6.28044 10.2575C8.53646 10.2575 9.7332 9.08498 9.7332 6.86131C9.7332 5.75351 9.41784 4.89638 8.78713 4.31419C8.15641 3.73199 7.22652 3.44089 6.02169 3.44089C5.16456 3.44089 4.33169 3.56218 3.61203 3.78051L3.55543 3.79668V3.85328V9.78847V9.85316L3.62012 9.86933C4.65514 10.12 5.55269 10.2575 6.28044 10.2575ZM5.96508 0.901855C2.36677 0.901855 0.604004 1.6296 0.604004 2.27649V16.8153C0.604004 17.3328 1.35601 17.5835 2.10802 17.5835C2.86811 17.5835 3.63629 17.3247 3.63629 16.8153V12.2062C4.85729 12.4892 5.89231 12.6186 6.82221 12.6186C11.0998 12.6186 12.7331 9.86934 12.7331 6.89365C12.7331 3.02041 10.2426 0.901855 5.96508 0.901855ZM6.28044 10.1766C5.55269 10.1766 4.67131 10.0472 3.63629 9.78847V3.85328C4.3883 3.61878 5.21308 3.51366 6.02169 3.51366C8.40708 3.51366 9.65234 4.67806 9.65234 6.85322C9.64426 8.74537 8.76287 10.1766 6.28044 10.1766Z" fill="white" />
      <path d="M26.5182 17.4055H16.7421C16.3539 17.4055 16.168 16.8314 16.168 16.2653V2.17127C16.168 1.59716 16.3782 1.07965 16.7421 1.07965H26.7446C27.9656 1.07965 26.6395 3.74806 25.4265 3.74806H19.2083V7.81537H24.6988C25.7096 7.81537 25.7096 10.4838 24.6988 10.4838H19.176V14.7371H26.5101C27.5532 14.7371 27.5532 17.4055 26.5182 17.4055Z" fill="white" />
      <path d="M26.7446 1.16051C26.882 1.16051 26.9872 1.20094 27.0519 1.27372C27.1165 1.34649 27.1489 1.45161 27.1489 1.58908C27.1489 1.95295 26.9306 2.47046 26.5909 2.90711C26.219 3.38419 25.7904 3.65912 25.4265 3.65912H19.2083H19.1275V3.73998V7.80728V7.88814H19.2083H24.6988C24.9495 7.88814 25.1112 8.09029 25.192 8.2601C25.3053 8.48651 25.3699 8.80996 25.3699 9.14149C25.3699 9.47302 25.3053 9.79646 25.192 10.0229C25.1031 10.1927 24.9495 10.3948 24.6988 10.3948H19.176H19.0951V10.4757V14.729V14.8098H19.176H26.5101C26.7688 14.8098 26.9306 15.012 27.0195 15.1818C27.1408 15.4082 27.2055 15.7317 27.2055 16.0632C27.2055 16.3947 27.1408 16.7182 27.0195 16.9446C26.9306 17.1144 26.7688 17.3165 26.5101 17.3165H16.7421C16.5076 17.3165 16.2488 16.985 16.2488 16.2573V2.17127C16.2488 1.68611 16.4186 1.16051 16.7421 1.16051H26.7446ZM26.7446 1.07965H16.7421C16.3782 1.07965 16.168 1.59716 16.168 2.17127V16.2653C16.168 16.8314 16.3459 17.4055 16.7421 17.4055H26.5101C27.5451 17.4055 27.5451 14.7371 26.5101 14.7371H19.176V10.4838H24.6988C25.7096 10.4838 25.7096 7.81537 24.6988 7.81537H19.2083V3.74806H25.4265C26.6475 3.74806 27.9656 1.07965 26.7446 1.07965Z" fill="white" />
      <path d="M41.1038 17.4055H31.3277C30.9396 17.4055 30.7617 16.8314 30.7617 16.2653V2.17127C30.7617 1.59716 30.972 1.07965 31.3277 1.07965H41.3302C42.5512 1.07965 41.2251 3.74806 40.0122 3.74806H33.794V7.81537H39.2845C40.2952 7.81537 40.2952 10.4838 39.2845 10.4838H33.7617V14.7371H41.0957C42.1389 14.7371 42.1389 17.4055 41.1038 17.4055Z" fill="white" />
      <path d="M41.3302 1.16051C41.4677 1.16051 41.5728 1.20094 41.6375 1.27372C41.7022 1.34649 41.7346 1.45161 41.7346 1.58908C41.7346 1.95295 41.5162 2.47046 41.1766 2.90711C40.8046 3.38419 40.3761 3.65912 40.0122 3.65912H33.794H33.7131V3.73998V7.80728V7.88814H33.794H39.2845C39.5351 7.88814 39.6969 8.09029 39.7777 8.2601C39.8909 8.48651 39.9556 8.80996 39.9556 9.14149C39.9556 9.47302 39.8909 9.79646 39.7777 10.0229C39.6888 10.1927 39.5351 10.3948 39.2845 10.3948H33.7617H33.6808V10.4757V14.729V14.8098H33.7617H41.0957C41.3545 14.8098 41.5162 15.012 41.6052 15.1818C41.7265 15.4082 41.7911 15.7317 41.7911 16.0632C41.7911 16.3947 41.7265 16.7182 41.6052 16.9446C41.5162 17.1144 41.3545 17.3165 41.0957 17.3165H31.3277C31.0932 17.3165 30.8426 16.985 30.8426 16.2573V2.17127C30.8426 1.68611 31.0124 1.16051 31.3277 1.16051H41.3302ZM41.3302 1.07965H31.3277C30.9639 1.07965 30.7617 1.59716 30.7617 2.17127V16.2653C30.7617 16.8314 30.9396 17.4055 31.3277 17.4055H41.0957C42.1308 17.4055 42.1308 14.7371 41.0957 14.7371H33.7617V10.4838H39.2845C40.2952 10.4838 40.2952 7.81537 39.2845 7.81537H33.794V3.74806H40.0122C41.2332 3.74806 42.5512 1.07965 41.3302 1.07965Z" fill="white" />
      <path d="M55.6406 20.5672C54.735 19.7909 53.9829 18.5699 53.5382 17.4298C52.9641 17.5349 52.3496 17.5834 51.6946 17.5834C46.8995 17.5834 44.0532 14.4703 44.0532 9.23855C44.0532 3.97451 46.9076 0.869446 51.6946 0.869446C56.5382 0.869446 59.3925 3.98259 59.3925 9.23855C59.3925 12.7075 58.1473 15.2223 55.8913 16.5484C56.4896 17.7128 57.4681 18.7478 58.5839 19.5807C59.7322 20.3569 56.3683 21.2868 55.6406 20.5672ZM51.7027 14.915C54.549 14.915 56.2147 12.8126 56.2147 9.23855C56.2147 5.64024 54.5571 3.53786 51.7027 3.53786C48.8806 3.53786 47.2472 5.64024 47.2472 9.23855C47.2472 12.8207 48.8806 14.915 51.7027 14.915Z" fill="white" />
      <path d="M51.7029 0.950307C54.0559 0.950307 55.9966 1.70231 57.3066 3.13355C58.6246 4.56479 59.3119 6.67526 59.3119 9.23855C59.3119 12.6509 58.1152 15.1495 55.8511 16.4756L55.7864 16.516L55.8187 16.5807C56.3766 17.6723 57.3146 18.7316 58.5437 19.6454C58.7863 19.8071 58.7459 19.9365 58.7297 19.9769C58.6165 20.3488 57.5087 20.7451 56.5788 20.7451C56.1583 20.7451 55.843 20.6642 55.6893 20.5106C54.8646 19.8071 54.0964 18.6508 53.6112 17.4055L53.5869 17.3408L53.5223 17.3489C52.9562 17.454 52.3417 17.5025 51.6948 17.5025C49.366 17.5025 47.4415 16.7505 46.1316 15.3193C44.8216 13.8881 44.1262 11.7857 44.1262 9.23855C44.1262 6.68335 44.8216 4.57288 46.1316 3.13355C47.4496 1.7104 49.3741 0.950307 51.7029 0.950307ZM51.7029 14.9959C53.126 14.9959 54.2985 14.4703 55.091 13.4676C55.8753 12.473 56.2958 11.0094 56.2958 9.23855C56.2958 7.45153 55.8834 5.97986 55.091 4.98527C54.2985 3.98259 53.126 3.457 51.7029 3.457C50.2959 3.457 49.1315 3.98259 48.3552 4.98527C47.579 5.97986 47.1666 7.45153 47.1666 9.23855C47.1666 11.0175 47.579 12.473 48.3552 13.4676C49.1315 14.4703 50.2959 14.9959 51.7029 14.9959ZM51.7029 0.869446C46.9078 0.869446 44.0615 3.98259 44.0615 9.23855C44.0615 14.4703 46.9159 17.5834 51.7029 17.5834C52.3498 17.5834 52.9724 17.5349 53.5465 17.4298C53.9832 18.5699 54.7352 19.7909 55.6489 20.5672C55.8349 20.7532 56.1907 20.8259 56.595 20.8259C57.7917 20.8259 59.4494 20.1629 58.6003 19.5807C57.4844 18.7478 56.4979 17.7128 55.9077 16.5484C58.1637 15.2303 59.4089 12.7156 59.4089 9.23855C59.4009 3.98259 56.5465 0.869446 51.7029 0.869446ZM51.7029 14.915C48.8808 14.915 47.2474 12.8126 47.2474 9.23855C47.2474 5.64024 48.8808 3.53786 51.7029 3.53786C54.5492 3.53786 56.2149 5.64024 56.2149 9.23855C56.2149 12.8207 54.5573 14.915 51.7029 14.915Z" fill="white" />
    </svg>
  </Link>
);

export default Logo;
