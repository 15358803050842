import React from 'react';
import TermsAndConditionsModal from '../../homePage/footer/pages/TermsAndConditionsModal';
import CheckSiteProtection from './CheckSiteProtection';

const LayoutContainer = ({ children }) => (
  <>
    <CheckSiteProtection />
    {children}
    <TermsAndConditionsModal />
  </>
);

export default LayoutContainer;
