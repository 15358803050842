import { useSelector } from 'react-redux';
import store from '../../store';

export const useAdmin = () => useSelector((state) => state?.session?.user?.administrator);
export const useAdministrator = () => useSelector((state) => (state?.session?.user?.administrator || state?.session?.user?.superAdministrator));

export const useManager = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  const manager = (state.session.user.userLimit || 0) !== 0;
  return admin || manager;
});

export const useDeviceReadonly = () => useSelector((state) => {
  const admin = state.session.user.administrator;
  const serverReadonly = state.session.server.readonly;
  const userReadonly = state.session.user.readonly;
  const serverDeviceReadonly = state.session.server.deviceReadonly;
  const userDeviceReadonly = state.session.user.deviceReadonly;
  return !admin && (serverReadonly || userReadonly || serverDeviceReadonly || userDeviceReadonly);
});

export const useRestriction = (key) => useSelector((state) => {
  if (state?.session?.user) {
    const admin = state.session.user.administrator;
    const serverValue = state.session.server[key];
    const userValue = state.session.user[key];
    return !admin && (serverValue || userValue);
  }
  return false;
});

export const useSuperAdministrator = () => useSelector((state) => state?.session?.user?.superAdministrator);

const twilioCommandAccessEmails = ['rdsecurelive@gmail.com', 'bradley.owoghiri@rocketdrop.com'];
const everythingAccessEmails = ['mark.correale@rocketdrop.com', 'zeeshanhameed.projectmarker@gmail.com', 'sadam.projectmarker@nxvt.com', 'carla@rocketdrop.com'];
export const useSuperAdministratorCanAccessTwilioCommand = () => useSelector((state) => state?.session?.user?.superAdministrator && twilioCommandAccessEmails.includes(state?.session?.user?.email));
export const useSuperAdministratorCanAccessEverything = () => useSelector((state) => state?.session?.user?.superAdministrator && everythingAccessEmails.includes(state?.session?.user?.email));

export const useLoggedInUser = () => useSelector((state) => state?.session?.user);

export const useAuthToken = () => store.getState().session.authToken || localStorage.getItem('auth-token');
