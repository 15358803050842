import React from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAdministrator } from '../../../common/util/permissions';

const PopularLinks = ({ scrollToTop }) => {
  const user = useSelector((state) => state.session.user);
  const administrator = useAdministrator();
  const profileLink = !user ? '/login' : (administrator ? `/settings/user/${user.id}` : '/settings/profile');
  return (
    <div className="links">
      <p className="links__heading">Popular Links</p>
      <ul>
        <li><Link to="/shop" onClick={scrollToTop}>Order</Link></li>
        <li><Link to={profileLink} onClick={scrollToTop}>My Account</Link></li>
        <li><Link to="/support" onClick={scrollToTop}>Support and FAQs</Link></li>
        <li><Link to="/about-us" onClick={scrollToTop}>About Us</Link></li>
      </ul>
    </div>
  );
};

export default PopularLinks;
