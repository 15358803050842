import React, { useState } from 'react';
import {
  Button, Grid, IconButton, Modal,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CircleSpinner from '../../../../CircleSpinner/CircleSpinner';
import CustomSelect from '../../../../homePage/footer/pages/CustomSelect';
import useForm from '../../../../hooks/useForm';
import { defaultStatusByActive, statusByActive } from '../../../../common/util/status';
import checkEmptyObject from '../../../../common/util/checkEmptyObject';
import customFetch from '../../../../common/util/customFetch';

const validationMessages = {
  question: 'Question is required',
  answer: 'Answer is required',
  categoryId: 'Category is required',
  sort: 'Sort Order is required',
  status: 'Status is required',
};

const CharacterLimitMessages = {
  question: 'Question is too long',
  answer: 'Answer is too long',
};

const CharacterLimits = {
  question: 100,
  answer: 350,
};

const AddFAQ = ({
  openFaqModal = false,
  handleClose = () => {},
  title = 'Create',
  existingFaq = {},
  setAlertMessage = () => {},
  setAlertHeaderMessage = () => {},
  setShowAlertMessage = () => {},
  categories = [],
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    register,
    values,
    setValues,
    errors,
    setErrors,
    isFormValid,
  } = useForm({
    initialValues: {
      question: existingFaq?.question || '',
      answer: existingFaq?.answer || '',
      categoryId: existingFaq?.categoryId || '',
      sort: existingFaq?.sort || '1',
      status: existingFaq?.status || defaultStatusByActive,
      createdAt: existingFaq?.createdAt || new Date(),
      updatedAt: existingFaq?.updatedAt || new Date(),
      ...(existingFaq?.id && { id: existingFaq?.id }),
    },
  });

  const saveFaq = async () => {
    if (isFormValid && checkEmptyObject(errors) && !checkEmptyObject(values)) {
      setShowSpinner(true);
      const url = existingFaq?.id ? `/api/faqs/${existingFaq?.id}` : '/api/faqs';
      const response = await customFetch(url, {
        method: existingFaq?.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values }),
      });
      const message = (
        <span>
          Your faq
          {/* {' '}
          <span style={{ color: '#1A74E2' }}>{values.question}</span> */}
          {' '}
          has been
          {' '}
          {`${title.toLowerCase()}d`}
          {' '}
          successfully.
        </span>
      );
      const alertHeaderMessage = `Faq ${title}d Successfully`;
      if (response.ok) {
        setAlertHeaderMessage(alertHeaderMessage);
        setAlertMessage(message);
        setShowAlertMessage(true);
        setShowSpinner(false);
        handleClose(true);
      }
    } else {
      const updatedErrors = {};
      Object.keys(values).forEach((key) => {
        if (!values[key] && validationMessages.hasOwnProperty(key)) {
          updatedErrors[key] = validationMessages[key];
        } else if (values[key] && CharacterLimits.hasOwnProperty(key) && values[key].length > CharacterLimits[key]) {
          updatedErrors[key] = CharacterLimitMessages[key];
        }
      });
      setErrors(updatedErrors);
    }
  };

  return (
    <Modal
      open={openFaqModal}
      onClose={handleClose}
      classes={{ root: 'center-with-flex' }}
    >
      <div
        className="create-testimonial-modal"
      >
        <div className="modal-header">
          <p>
            {title}
            {' '}
            FAQ
          </p>
          <IconButton
            className="cross-btn"
            onClick={handleClose}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        </div>
        <div className="modal-body">
          <div className="inner-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="form">
                  <TextField
                    label="Question"
                    autoFocus
                    {...register('question', {
                      validate: (v) => (v ? (v.length > CharacterLimits.question ? CharacterLimitMessages.question : null) : validationMessages.question),
                    })}
                    error={!!errors.question}
                    helperText={errors.question ? errors.question : ''}
                    placeholder="Enter Question"
                    className="form-textfield"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-textarea">
                  <p className="label">
                    <span className="label-tag">Answer</span>
                    <span className="info-required">*</span>
                  </p>
                  <div className={errors.answer ? 'textarea error-field' : 'textarea'}>
                    <TextareaAutosize
                      required
                      autoFocus
                      label="Answer"
                      name="Answer"
                      {...register('answer', {
                        validate: (v) => (v ? (v.length > CharacterLimits.answer ? CharacterLimitMessages.answer : null) : validationMessages.answer),
                      })}
                      placeholder="Enter Answer"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  {(errors.answer) && (<div className="text-error">{errors.answer}</div>)}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={errors.categoryId && 'error-field'}>
                <CustomSelect
                  title="Category"
                  required
                  {...register('categoryId', {
                    validate: (v) => (v ? null : validationMessages.categoryId),
                  })}
                  error={!!errors.categoryId}
                  helperText={errors.categoryId ? errors.categoryId : ''}
                  placeholder={
                    <div className="select-placeholder-text">Select Category</div>
              }
                  options={categories}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomSelect
                  title="Status"
                  required
                  {...register('status', {
                    validate: (v) => (v ? null : validationMessages.status),
                  })}
                  error={!!errors.status}
                  helperText={errors.status ? errors.status : ''}
                  placeholder={
                    <div className="select-placeholder-text">{defaultStatusByActive}</div>
              }
                  options={statusByActive}
                />
                {(errors.status) && (<div className="text-error">{errors.status}</div>)}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="form">
                  <TextField
                    type="number"
                    {...register('sort', {
                      validate: (v) => (v ? null : validationMessages.sort),
                    })}
                    onChange={(e) => {
                      if (e.target.value > 0 || e.target.value === '') {
                        setValues((prevState) => ({ ...prevState, sort: parseInt(e.target.value, 10) }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        setValues((prevState) => ({ ...prevState, sort: 1 }));
                      }
                    }}
                    error={!!errors.sort}
                    helperText={errors.sort ? errors.sort : ''}
                    label="Sort Order"
                    placeholder="1"
                    className={errors.sort ? 'form-textfield error-field' : 'form-textfield'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="modal-footer">
            {showSpinner ? <CircleSpinner /> : (
              <Button
                className="btn btn--contained"
                variant="contained"
                onClick={saveFaq}
              >
                {existingFaq?.question ? 'Update' : 'Save'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddFAQ;
