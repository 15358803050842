import React, { useState } from 'react';
import { Alert, IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectAsync } from './reactHelper';
import { sessionActions } from './store';
import customFetch from './common/util/customFetch';
import ScreenLoader from './common/components/ScreenLoader';

const ServerProvider = ({
  children,
}) => {
  const dispatch = useDispatch();

  const initialized = useSelector((state) => !!state.session.server);
  const [error, setError] = useState(null);

  useEffectAsync(async () => {
    if (!error) {
      try {
        const [serverResponse, sessionResponse] = await Promise.all([
          customFetch('/api/server'),
          customFetch('/api/session'),
        ]);

        if (serverResponse.ok) {
          dispatch(sessionActions.updateServer(await serverResponse.json()));
        } else {
          throw Error(await serverResponse.text());
        }
        if (sessionResponse.ok) {
          dispatch(sessionActions.updateUser(await sessionResponse.json()));
        }
      } catch (error) {
        setError(error.message);
      }
    }
  }, [error]);

  if (error) {
    return (
      <Alert
        severity="error"
        action={(
          <IconButton color="inherit" size="small" onClick={() => setError(null)}>
            <ReplayIcon fontSize="inherit" />
          </IconButton>
        )}
      >
        {error}
      </Alert>
    );
  }
  if (!initialized) {
    return (<ScreenLoader />);
  }
  return children;
};

export default ServerProvider;
