import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../store';

const SubscriptionItemsDropdown = ({ subscriptionItems }) => {
  const dispatch = useDispatch();
  const subscriptionRef = useRef(null);
  const orderSubscription = useSelector((state) => state.cart.order.orderSubscription);

  const [isSubscriptionPlanOpen, setIsSubscriptionPlanOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    if (orderSubscription && orderSubscription.planId) {
      const item = subscriptionItems.find((item) => item.id === orderSubscription.planId);
      if (item) {
        setSelectedSubscription(item);
      }
    }
  }, [orderSubscription, subscriptionItems]);

  const handleSubscriptionChange = (item) => {
    dispatch(cartActions.setOrderSubscription({ planId: item.id, price: item.price, oldPrice: item.oldPrice }));
    setSelectedSubscription(item);
    setIsSubscriptionPlanOpen(!isSubscriptionPlanOpen);
    dispatch(cartActions.resetOrderCouponCodeDetails());
  };

  return (
    <div className="subscription-plan">
      <Button disableRipple className="subscription-plan__container" onClick={() => setIsSubscriptionPlanOpen(!isSubscriptionPlanOpen)}>
        <p style={{ fontSize: selectedSubscription ? '14px' : '12px' }}>{selectedSubscription ? selectedSubscription.name : 'No Subscription'}</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none" className={isSubscriptionPlanOpen && 'active-arrow'}>
          <path d="M4.50247 3.6196L7.90536 0.194872C8.0265 0.0734874 8.18941 0.00549316 8.35907 0.00549316C8.52874 0.00549316 8.69164 0.0734874 8.81279 0.194872C8.8721 0.254805 8.91918 0.326165 8.95132 0.404821C8.98345 0.483476 9 0.567865 9 0.653099C9 0.738334 8.98345 0.822721 8.95132 0.901377C8.91918 0.980032 8.8721 1.05139 8.81279 1.11133L4.95619 4.99196C4.83819 5.1094 4.68094 5.17664 4.51626 5.18008C4.35158 5.18353 4.19177 5.12291 4.06917 5.01051L0.185343 1.11364C0.125852 1.05382 0.0786047 0.982491 0.0463543 0.903821C0.0141039 0.825152 -0.00250435 0.74071 -0.00250435 0.655417C-0.00250435 0.570123 0.0141039 0.485682 0.0463543 0.407012C0.0786047 0.328343 0.125852 0.257017 0.185343 0.19719C0.306491 0.0758057 0.469393 0.00781155 0.639062 0.00781155C0.80873 0.00781155 0.971632 0.0758057 1.09278 0.19719L4.50247 3.6196Z" fill="#8899B4" />
        </svg>
      </Button>
      {
          (isSubscriptionPlanOpen && subscriptionItems.length) && (
          <div className="subscription-plan__dropdown" ref={subscriptionRef}>
            {
                      subscriptionItems.map((item) => (
                        <div className={`dropdown-item ${item.description === selectedSubscription?.description && 'active-item'}`} onClick={() => handleSubscriptionChange(item)} onKeyDown={() => {}} role="button" tabIndex={0}>
                          <p className="duration">{item.name}</p>
                        </div>
                      ))
                  }
          </div>
          )
            }
    </div>
  );
};
export default SubscriptionItemsDropdown;
