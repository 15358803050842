import React from 'react';

const ContactItem = ({ icon, title, desc }) => (
  <div className="contact-item">
    <img src={icon} alt={title} width="32" height="32" />
    <div className="contact-item__info-sub">
      <p className="title">{title}</p>
      {title === 'Contact us at' ? (
        <a href={`mailto:${desc}`} className="desc">{desc}</a>
      ) : (
        <p className="desc">{desc}</p>
      )}
    </div>
  </div>
);

export default ContactItem;
