import { useNavigate } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';
import customFetch from '../util/customFetch';

const CheckSiteProtection = () => {
  const navigate = useNavigate();

  const disablePasswordProtection = process.env.REACT_APP_DISABLE_PASSWORD_PROTECTION;
  const siteProtectionUserName = window.localStorage.getItem('siteProtectionUserName');
  const siteProtectionPassword = window.localStorage.getItem('siteProtectionPassword');
  const isPasswordProtectionDisabled = disablePasswordProtection === 'true';
  const hasSiteProtectionCredentials = siteProtectionUserName && siteProtectionPassword;

  const setPasswordProtection = (value) => {
    window.localStorage.setItem('passwordProtection', value);
  };

  const clearSiteProtectionCredentials = () => {
    window.localStorage.removeItem('passwordProtection');
    window.localStorage.removeItem('siteProtectionUserName');
    window.localStorage.removeItem('siteProtectionPassword');
  };

  useEffectAsync(async () => {
    if (isPasswordProtectionDisabled) {
      setPasswordProtection(true);
    } else if (hasSiteProtectionCredentials) {
      const response = await customFetch('/api/session/verifyUserBySiteLevel', {
        method: 'POST',
        body: new URLSearchParams({
          userName: siteProtectionUserName,
          password: siteProtectionPassword,
        }),
      });

      if (response.ok) {
        const verified = await response.text();
        if (verified === 'verified') {
          setPasswordProtection(true);
        } else {
          clearSiteProtectionCredentials();
          navigate('/password-protection');
        }
      }
    } else {
      clearSiteProtectionCredentials();
      navigate('/password-protection');
    }
  }, []);

  return null;
};

export default CheckSiteProtection;
