import React, { useEffect, useRef } from 'react';

import { DateRangePicker, createStaticRanges } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DatePicker = ({ dateRange, handleDateRange, handleClose }) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClose]);

  return (
    <div className="custom-datepicker" ref={datePickerRef}>
      <DateRangePicker
        onChange={(item) => {
          handleDateRange([item.range1]);
        }}
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        months={1}
        color="fece2f"
        ranges={dateRange}
        direction="horizontal"
        staticRanges={createStaticRanges([])}
        inputRanges={[]}
      />
    </div>
  );
};

export default DatePicker;
