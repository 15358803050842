import React from 'react';
import Lottie from 'react-lottie-player';
import Confetti from '../../resources/loader.json';

const ScreenLoader = () => (
  <div className="loader-animation">
    <Lottie
      animationData={Confetti}
      loop
      play
      style={{ width: 300, height: 300 }}
    />
  </div>
);
export default ScreenLoader;
