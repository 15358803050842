const percentages = [
  { value: 4112, percentage: 100 },
  { value: 4079, percentage: 99 },
  { value: 4066, percentage: 98 },
  { value: 4054, percentage: 97 },
  { value: 4044, percentage: 96 },
  { value: 4034, percentage: 95 },
  { value: 4024, percentage: 94 },
  { value: 4014, percentage: 93 },
  { value: 4005, percentage: 92 },
  { value: 3996, percentage: 91 },
  { value: 3987, percentage: 90 },
  { value: 3979, percentage: 89 },
  { value: 3972, percentage: 88 },
  { value: 3965, percentage: 87 },
  { value: 3957, percentage: 86 },
  { value: 3948, percentage: 85 },
  { value: 3937, percentage: 84 },
  { value: 3927, percentage: 83 },
  { value: 3917, percentage: 82 },
  { value: 3908, percentage: 81 },
  { value: 3900, percentage: 80 },
  { value: 3892, percentage: 79 },
  { value: 3885, percentage: 78 },
  { value: 3879, percentage: 77 },
  { value: 3873, percentage: 76 },
  { value: 3867, percentage: 75 },
  { value: 3861, percentage: 74 },
  { value: 3855, percentage: 73 },
  { value: 3849, percentage: 72 },
  { value: 3842, percentage: 71 },
  { value: 3836, percentage: 70 },
  { value: 3829, percentage: 69 },
  { value: 3823, percentage: 68 },
  { value: 3817, percentage: 67 },
  { value: 3810, percentage: 66 },
  { value: 3804, percentage: 65 },
  { value: 3798, percentage: 64 },
  { value: 3792, percentage: 63 },
  { value: 3786, percentage: 62 },
  { value: 3779, percentage: 61 },
  { value: 3773, percentage: 60 },
  { value: 3767, percentage: 59 },
  { value: 3762, percentage: 58 },
  { value: 3756, percentage: 57 },
  { value: 3751, percentage: 56 },
  { value: 3746, percentage: 55 },
  { value: 3741, percentage: 54 },
  { value: 3736, percentage: 53 },
  { value: 3732, percentage: 52 },
  { value: 3728, percentage: 51 },
  { value: 3724, percentage: 50 },
  { value: 3720, percentage: 49 },
  { value: 3716, percentage: 48 },
  { value: 3713, percentage: 47 },
  { value: 3710, percentage: 46 },
  { value: 3707, percentage: 45 },
  { value: 3704, percentage: 44 },
  { value: 3701, percentage: 43 },
  { value: 3698, percentage: 42 },
  { value: 3695, percentage: 41 },
  { value: 3693, percentage: 40 },
  { value: 3690, percentage: 39 },
  { value: 3688, percentage: 38 },
  { value: 3685, percentage: 37 },
  { value: 3683, percentage: 36 },
  { value: 3681, percentage: 35 },
  { value: 3679, percentage: 34 },
  { value: 3677, percentage: 33 },
  { value: 3675, percentage: 32 },
  { value: 3675, percentage: 32 },
  { value: 3673, percentage: 31 },
  { value: 3672, percentage: 30 },
  { value: 3670, percentage: 29 },
  { value: 3668, percentage: 28 },
  { value: 3666, percentage: 27 },
  { value: 3664, percentage: 26 },
  { value: 3662, percentage: 25 },
  { value: 3660, percentage: 24 },
  { value: 3658, percentage: 23 },
  { value: 3655, percentage: 22 },
  { value: 3653, percentage: 21 },
  { value: 3650, percentage: 20 },
  { value: 3647, percentage: 19 },
  { value: 3644, percentage: 18 },
  { value: 3640, percentage: 17 },
  { value: 3636, percentage: 16 },
  { value: 3632, percentage: 15 },
  { value: 3628, percentage: 14 },
  { value: 3622, percentage: 13 },
  { value: 3617, percentage: 12 },
  { value: 3611, percentage: 11 },
  { value: 3605, percentage: 10 },
  { value: 3598, percentage: 9 },
  { value: 3591, percentage: 8 },
  { value: 3586, percentage: 7 },
  { value: 3582, percentage: 6 },
  { value: 3578, percentage: 5 },
  { value: 3573, percentage: 4 },
  { value: 3568, percentage: 3 },
  { value: 3561, percentage: 2 },
  { value: 3549, percentage: 1 },
];

export default (batteryValue) => {
  const battery = parseInt(batteryValue * 1000, 10);
  if (battery >= 4112) {
    return 100;
  }
  if (battery < 3549 && battery > 0) {
    return '<1';
  }
  const percentageLength = percentages.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < percentageLength; i++) {
    const nextIndex = (i === (percentageLength - 1) ? (percentageLength - 1) : (i + 1));
    if (percentages[i].value >= battery && percentages[nextIndex].value <= battery) {
      return percentages[nextIndex].percentage;
    }
  }
  return 0;
};

export const percentageToValue = (percentage) => {
  let value = 3549;
  if (+percentage >= 100) {
    value = 4112;
  }
  const fullValue = percentages.find((p) => p.percentage === +percentage);
  if (fullValue) {
    value = fullValue.value;
  }
  return parseFloat((value / 1000).toFixed(3));
};
