import { useAuthToken } from './permissions';

export default async (url, options) => {
  const token = useAuthToken();
  if (url === '/api/session' && !token && !options) {
    return {
      ok: false,
    };
  }
  const { headers, ...otherOptions } = options || {};

  const newHeaders = {
    ...(headers || {}),
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  const requestOptions = {
    ...otherOptions,
    headers: newHeaders,
  };
  const apiUrl = process.env.REACT_APP_URL_API + url;
  return fetch(apiUrl, requestOptions);
};
