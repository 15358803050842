import React from 'react';
import EmailInput from './EmailInput';

const ReachOutToUs = () => (
  <div className="reach-out">
    <p className="reach-out__heading">Subscribe</p>
    <p className="reach-out__description">Be the first to hear about new product features, updates and special offers.</p>
    <EmailInput />
  </div>
);

export default ReachOutToUs;
