import React, { useState } from 'react';
import { Button, Popover } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userflow from 'userflow.js';
import UserMenu from './UserMenu';
import LoggedInUserNotification from '../../../landingPage/Header/LoggedInUser/LoggedInUserNotification';
import { CONSTANTS } from '../../../constants';
import { ReactComponent as ActivatePomIcon } from '../../../resources/images/helpful-links/active-pom-icon.svg';
import { ReactComponent as OrderIcon } from '../../../resources/images/helpful-links/order-icon.svg';
import { ReactComponent as TakeTourIcon } from '../../../resources/images/helpful-links/take-tour-icon.svg';
import { ReactComponent as SupportIcon } from '../../../resources/images/helpful-links/support-icon.svg';
import { ReactComponent as ContactUsIcon } from '../../../resources/images/helpful-links/contact-us-icon.svg';

const LoggedInUser = () => {
  const location = useLocation();
  const path = location?.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClose = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen((prevState) => !prevState);
  const currentUser = useSelector((state) => state.session.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showQuickTakeATourButton = !!(currentUser && (path === '/devices' || path === '/geofences?geofenceAction=create' || path === '/geofences' || path.includes('/settings')));
  const handleIconClick = (event) => {
    event.stopPropagation();
    toggleMenu();
  };

  const handleQuickTakeATour = () => {
    setAnchorEl(null);
    const flowValue = (path === '/geofences?geofenceAction=create' || path === '/geofences') ? CONSTANTS.createGeofenceFlow : CONSTANTS.allTrackerFlow;
    userflow.start(flowValue);
  };

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      {showQuickTakeATourButton && (
        <>
          <Button aria-describedby="helpful-link-popover" onClick={handleClickPopover} className="tour-btn">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.1641 23.2344C18.343 23.2344 23.3984 18.1789 23.3984 12C23.3984 5.82109 18.343 0.765625 12.1641 0.765625C5.98516 0.765625 0.929688 5.82109 0.929688 12C0.929688 18.1789 5.98516 23.2344 12.1641 23.2344Z" stroke="#021021" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.9844 16.5938H12.9981" stroke="#021021" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.4609 12.7659V12.4448C12.4609 11.4047 13.1034 10.854 13.7458 10.4104C14.373 9.98212 15 9.43149 15 8.42197C15 7.01475 13.8682 5.88281 12.4609 5.88281C11.0537 5.88281 9.92188 7.01475 9.92188 8.42197" stroke="#021021" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
          <Popover
            id="helpful-link-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 30,
              horizontal: 10,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 150,
            }}
            disablePortal
          >
            <h4>Helpful Links</h4>
            <ul>
              {/* <li>
                <a href="/activate-pom" target="_blank" rel="noopener noreferrer" className="stroke">
                  <ActivatePomIcon />
                  <span>Activate POM</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/shop" target="_blank" rel="noopener noreferrer" className="stroke">
                  <OrderIcon />
                  <span>Order</span>
                </a>
              </li> */}
              <li>
                <a href="#" onClick={(e) => { e.preventDefault(); handleQuickTakeATour(); }} className="fill">
                  <TakeTourIcon />
                  <span>Take a Tour</span>
                </a>
              </li>
              <li>
                <a href="/support" target="_blank" rel="noopener noreferrer" className="fill">
                  <SupportIcon />
                  <span>Support and FAQs</span>
                </a>
              </li>
              <li>
                <a href="/contact-us" target="_blank" rel="noopener noreferrer" className="stroke">
                  <ContactUsIcon />
                  <span>Contact Us</span>
                </a>
              </li>
            </ul>
          </Popover>
        </>
      )}

      <LoggedInUserNotification />
      <div className="logged-in-user">
        <Button className="logged-in-btn" onClick={handleIconClick}>
          <img src={currentUser.profileImageUrl ? `${process.env.REACT_APP_BUCKET_URL}/${currentUser.profileImageUrl}` : '/default-dp.png'} alt="user-pic" />
        </Button>
        <UserMenu open={isMenuOpen} handleClose={handleClose} />
      </div>
    </>
  );
};

export default LoggedInUser;
