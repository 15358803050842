import React from 'react';

const AuthPageLayout = ({ children }) => {
  const backgroundImageUrl = `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_VERSION}/not-found-bg.webp`;
  return (
    <div className="onboarding-banner" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <div className="form-section">
        {children}
      </div>
    </div>
  );
};
export default AuthPageLayout;
