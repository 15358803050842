import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const ActivityLogAccordion = ({ status }) => (
  <Accordion className="activity-log-accordion">
    <AccordionSummary
      expandIcon={(
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 7L13 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
)}
      aria-controls="panel1-content"
      id="panel1-header"
    >
      <div className="panel-header">
        <p>24 June, 2024</p>
        <div className={`status-counter ${status}`}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#1A74E2" />
          </svg>
          <span>8 updates </span>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <div className="activity-list">
        <div className="day-activity-list">
          <span className="time">12:59 PM CEST</span>
          <p>
            Testimonial of
            {' '}
            <span>Kole J.</span>
            {' '}
            has been changed by admin
            {' '}
            <span>John Doe</span>
          </p>
        </div>
        <div className="day-activity-list">
          <span className="time">10:00 AM CEST</span>
          <p>
            Testimonial of
            {' '}
            <span>Tony H.</span>
            {' '}
            has been deleted by admin
            {' '}
            <span>Martin</span>
          </p>
        </div>
        <div className="day-activity-list">
          <span className="time">10:00 AM CEST</span>
          <p>
            New testimonial of
            {' '}
            <span>Kole J.</span>
            {' '}
            has been added by admin
            {' '}
            <span>Joe</span>
          </p>
        </div>
      </div>
    </AccordionDetails>
  </Accordion>
);

export default ActivityLogAccordion;
