import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TrashIcon } from '../../../resources/images/cart-dropdown/trash-icon.svg';
import { cartActions } from '../../../store';
import { formatPrice } from '../../../common/util/formatter';

const CartItem = ({ item, toggleCartOpen }) => {
  const dispatch = useDispatch();
  const cartQuantity = useSelector((state) => state.cart.order.quantity);
  const MAXALLOWEDCARTQUANTITY = useSelector((state) => state.cart.MAXALLOWEDCARTQUANTITY);

  const sliderImages = {
    blue: {
      front: `${process.env.REACT_APP_BUCKET_URL}/products/blue_front.png`,
    },
    white: {
      front: `${process.env.REACT_APP_BUCKET_URL}/products/white_front.png`,
    },
  };

  const handleIncrement = (item) => {
    if (cartQuantity < MAXALLOWEDCARTQUANTITY) {
      dispatch(cartActions.setCartQuantity({ quantity: item.quantity + 1, selectedItem: item }));
      dispatch(cartActions.setShowMaxQuantityAlert(false));
      dispatch(cartActions.resetOrderCouponCodeDetails());
    } else {
      dispatch(cartActions.setShowMaxQuantityAlert(true));
    }
  };
  const handleDecrement = (item) => {
    if (item.quantity > 1) {
      dispatch(cartActions.setCartQuantity({ quantity: item.quantity - 1, selectedItem: item }));
      dispatch(cartActions.resetOrderCouponCodeDetails());
    }
  };
  const handleResetItem = (item) => {
    dispatch(cartActions.resetCartOrderItem(item));
    dispatch(cartActions.resetOrderCouponCodeDetails());
  };
  const navigate = useNavigate();
  return (
    <div className="cart-item">
      <Button
        className="product-img-link"
        onClick={() => {
          toggleCartOpen();
          navigate('/shop');
        }}
      >
        <div className="product-image">
          <img src={sliderImages[item.imageName]?.front} alt="product-img" />
        </div>
      </Button>
      <div className="product-detail">
        <div className="name-price">
          <p className="name">
            {item.name}
            <div className="color">
              Color :
              {' '}
              <span>{item.color}</span>
            </div>
          </p>
          <div className="product-total-price-section">
            <div className="price-with-discount">
              {/* <span className="discounted-price">{`$${((parseInt(item.oldPrice, 10)) * item.quantity).toFixed(2)}`}</span> */}
              <span className="price">{`$${formatPrice((item.price * item.quantity))}`}</span>
              <span className="qty-price">{`$${item.price} x ${item.quantity}`}</span>
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <div className="product-qty">
            <div className="item-counter">
              <Button className="btn" onClick={() => handleDecrement(item)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="2" viewBox="0 0 9 2" fill="none">
                  <path d="M1 1H7.8" stroke={item.quantity === 1 ? '#8899B4' : '#000000'} strokeWidth="1.275" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Button>
              <FormControl className="qty-form">
                <TextField
                  id="outlined-number"
                  type="number"
                  className="form-textfield"
                  onChange={(e) => {
                    const quantity = parseInt(e.target.value, 10);
                    const totalQuantity = cartQuantity - item.quantity + quantity;

                    if (quantity > 0 && totalQuantity <= MAXALLOWEDCARTQUANTITY) {
                      // If quantity is positive and total quantity is within MAXALLOWEDCARTQUANTITY, update quantity directly
                      dispatch(cartActions.setCartQuantity({ quantity, selectedItem: item }));
                      dispatch(cartActions.setShowMaxQuantityAlert(false));
                    } else if (totalQuantity > MAXALLOWEDCARTQUANTITY) {
                      // If total quantity exceeds MAXALLOWEDCARTQUANTITY, adjust quantity to ensure it's within limits
                      const newQuantity = Math.min(MAXALLOWEDCARTQUANTITY - (cartQuantity - item.quantity), MAXALLOWEDCARTQUANTITY);
                      dispatch(cartActions.setCartQuantity({ quantity: newQuantity, selectedItem: item }));
                      dispatch(cartActions.setShowMaxQuantityAlert(true));
                    }
                  }}
                  value={item.quantity}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </FormControl>
              <Button className="btn" onClick={() => handleIncrement(item)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                  <path d="M4.39844 1V7.8" stroke={cartQuantity === 50 ? '#8899B4' : '#000000'} strokeWidth="1.275" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 4.40002H7.8" stroke={cartQuantity === 50 ? '#8899B4' : '#000000'} strokeWidth="1.275" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Button>
            </div>
          </div>
          <TrashIcon className="trash" onClick={() => handleResetItem(item)} />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
