export const prefixString = (prefix, value) => prefix + value.charAt(0).toUpperCase() + value.slice(1);

export const unprefixString = (prefix, value) => value.charAt(prefix.length).toLowerCase() + value.slice(prefix.length + 1);

export const stringToArray = (str) => {
  if (Array.isArray(str)) {
    return str; // If already an array, return it
  }

  return str
    .split(/[\n,]+/) // Split by new line or comma
    .map((item) => item.trim()) // Trim each item
    .filter(Boolean); // Filter out empty strings
};
