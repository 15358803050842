export const RESTRICTED_COUNTRY_CODES = [
  'KP', // North Korea
  'AO', // Angola
  'MD', // Moldova
  'IR', // Iran
  'SY', // Syria
  'CU', // Cuba
  'RU', // Russia
  'UA', // Ukraine
  'BY', // Belarus
  'MM', // Burma (Myanmar)
  'CF', // Central African Republic
  'CG', // Congo
  'ET', // Ethiopia
  'IQ', // Iraq
  'LB', // Lebanon
  'LY', // Libya
  'SD', // Sudan
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];

// added another useless import to circumvent eslint rules
export const DEFAULT_COUNTRY = 'United States';

export const CONSTANTS = {
  allTrackerFlow: 'e0c9a68a-10ec-4390-9b93-d9d5991150ca',
  createGeofenceFlow: '5c174bfc-8a33-4042-947e-db7356c026b8',
  device: 'POM',
  devices: 'POMs',
  googleTagId: 'G-XQ31E5CVTT',
  googleAddressAPIKey: 'AIzaSyCnqypDHqYdhLHxJc1h2UK9FrlEk57Mlgs',
};
