export const status = [{ key: 'enable', value: 'Enable' }, { key: 'disable', value: 'Disable' }];

export const getStatus = (key) => {
  const s = status.find((s) => s.key === key);
  return s?.value || 'Enable';
};

export const defaultStatus = 'enable';

export const statusByActive = [{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }, { value: 'draft', label: 'Draft' }];

export const getStatusActive = (key) => {
  const s = statusByActive.find((s) => s.value === key);
  return s?.label || 'Active';
};

export const defaultStatusByActive = 'active';
