import React, { useState } from 'react';
import {
  Button, IconButton, Input, Modal,
} from '@mui/material';
import { addDays } from 'date-fns';
import DatePicker from './DatePicker';
import ActivityLogAccordion from './ActivityLogAccordion';
import { formatTime } from '../../../../common/util/formatter';

const FAQActivityModal = ({
  activityModal, setActivityModal,
}) => {
  const handleClose = () => {
    setActivityModal(!activityModal);
  };
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
    },
  ]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleDateRange = (range) => {
    setDateRange(range);
    const endDateObj = range[0].endDate;
    const startDateObj = range[0].startDate;
    let endDate = JSON.stringify(endDateObj).replaceAll('"', '');
    let startDate = JSON.stringify(startDateObj).replaceAll('"', '');
    endDate = new Date(endDate);
    startDate = new Date(startDate);
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    endDate = endDate.toLocaleDateString('en-US', dateOptions);
    startDate = startDate.toLocaleDateString('en-US', dateOptions);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleShowDatepicker = () => setShowDatepicker((prevState) => !prevState);
  const handleDatePickerClose = () => setShowDatepicker(false);
  return (
    <Modal
      open={activityModal}
      onClose={handleClose}
      classes={{ root: 'center-with-flex' }}
    >
      <div
        className="testimonial-activity-modal"
      >
        <div className="modal-header">
          <p>
            Activity Log
          </p>
          <IconButton
            className="cross-btn"
            onClick={handleClose}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        </div>
        <div className="modal-body">
          <div className="inner-content">
            <div className="search-header">
              <div className="searchbar">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 17.3349L13.2477 13.1194M15.3503 8.10122C15.3532 9.97355 14.5992 11.7738 13.2477 13.1226C12.5919 13.7801 11.8044 14.3033 10.9326 14.6608C10.0608 15.0182 9.12288 15.2024 8.17504 15.2024C7.22719 15.2024 6.28899 15.0182 5.41719 14.6608C4.5454 14.3033 3.75794 13.7801 3.10207 13.1226C1.75321 11.7722 1 9.97296 1 8.10122C1 6.22948 1.75321 4.43024 3.10207 3.07983C3.75794 2.42233 4.5454 1.89912 5.41719 1.54169C6.28899 1.18427 7.22719 1 8.17504 1C9.12288 1 10.0608 1.18427 10.9326 1.54169C11.8044 1.89912 12.5919 2.42233 13.2477 3.07983C14.5992 4.42862 15.3532 6.22889 15.3503 8.10122Z" stroke="#BDC9E2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Input placeholder="Search" />
              </div>
              <div className="status-container">
                <div className="date-picker-container">
                  <Button
                    disableRipple
                    className="date-picker"
                    onClick={handleShowDatepicker}
                  >
                    <p>
                      {formatTime(startDate, 'date')}
                      &nbsp;-&nbsp;
                      {formatTime(endDate, 'date')}
                    </p>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.33398 1.83203V4.58203" stroke="#1A74E2" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.666 1.83203V4.58203" stroke="#1A74E2" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.20898 8.33203H18.7923" stroke="#1A74E2" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M19.25 7.79036V15.582C19.25 18.332 17.875 20.1654 14.6667 20.1654H7.33333C4.125 20.1654 2.75 18.332 2.75 15.582V7.79036C2.75 5.04036 4.125 3.20703 7.33333 3.20703H14.6667C17.875 3.20703 19.25 5.04036 19.25 7.79036Z" stroke="#1A74E2" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.3869 12.5599H14.3951" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.3869 15.3099H14.3951" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.9963 12.5599H11.0045" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.9963 15.3099H11.0045" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.60369 12.5599H7.61192" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.60369 15.3099H7.61192" stroke="#1A74E2" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                  </Button>
                  {showDatepicker && (
                  <DatePicker
                    dateRange={dateRange}
                    showDatepicker={showDatepicker}
                    handleShowDatepicker={handleShowDatepicker}
                    handleDateRange={handleDateRange}
                    handleClose={handleDatePickerClose}
                  />
                  )}
                </div>
              </div>
            </div>
            <div className="activity-log-status">
              <ActivityLogAccordion status="unread" />
              <ActivityLogAccordion status="read" />
              <ActivityLogAccordion status="read" />
              <ActivityLogAccordion status="unread" />

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FAQActivityModal;
