const messages = [
  { error: 'tc_devices.uniqueid', message: 'Device already added.' },
  { error: 'PermissionsService', message: 'Permission denied! Please contact to Administrator.' },
  { error: 'Authorization', message: 'Login session expired! Please login again.' },
  { error: 'Unauthorized', message: 'You are not authorized! Please contact to Administrator.' },
  { error: 'Phone is not configured with device', message: 'Phone is not configured with device.' },
  { error: 'Cannot delete or update a parent row: a foreign key constraint fails', message: 'Can not delete item. Item is associated OR anywhere else.' },
  { error: 'GoogleGeocode', message: 'Getting address.' },
  { error: 'Empty address', message: 'Getting address.' },
  { error: 'Attempt to send to unsubscribed recipient', message: 'Number is unsubscribed.' },
  { error: 'Unexpected end of JSON input', message: 'Something went wrong.' },
  { error: 'Too Many Requests', message: 'Experiencing high traffic, please try again later.' },
  { error: 'User does not have any order', message: 'Account not verified. Please check your email to verify or resend the verification email.' },
  { error: 'User account is not verified', message: 'Account not verified. Please check your email to verify or resend the verification email.' },
];

export default (error) => {
  if (error && typeof error === 'string') {
    const errorMessage = messages.find((m) => error.search(m.error) !== -1);
    return errorMessage ? errorMessage.message : error;
  }
  return error;
};
