import {
  isValidPhoneNumber,
} from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';
import customFetch from './customFetch';

const removePlusIfNeeded = (inputString) => {
  if (inputString.startsWith('+')) {
    return inputString.replace(/\+/g, '');
  }
  return inputString;
};

export const validatePhone = (phone) => {
  try {
    if (isValidPhoneNumber(`+${removePlusIfNeeded(phone)}`)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const validateEmail = (email) => {
  try {
    return isEmail(email);
  } catch (e) {
    return false;
  }
};

export const validatePassword = (password) => {
  try {
    if (password) {
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);
      const hasNumber = /\d/.test(password);
      const isLengthValid = password.length >= 8;
      const hasNoSpaces = !/\s/.test(password);
      const noSpacesAtEdges = password.trim() === password;

      return hasUppercase && hasLowercase && hasSpecialChar && hasNumber && isLengthValid && hasNoSpaces && noSpacesAtEdges;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const checkUniqueEmail = async (email) => {
  const response = await customFetch(`/api/users/checkUniqueEmail?email=${email}`);
  if (response.ok) {
    const userText = await response.text();
    if (userText === 'Email exist') {
      return false;
    }
  }
  return true;
};

export const validateIMEI = (str) => {
  if (str.length !== 14 && str.length !== 15) {
    return false;
  }
  return true;
};
