import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import TestimonialActivityModal from './Modals/TestimonialActivityModal';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DeleteTestimonialModal from './Modals/DeleteTestimonialModal';
import CreateTestimonial from './Modals/CreateTestimonial';
import { getStatusActive } from '../../../common/util/status';

const TestimonialArray = ({
  testimonial = {},
  responseiveWidth = 1025,
  setAlertMessage = () => {},
  setAlertHeaderMessage = () => {},
  setShowAlertMessage = () => {},
  handleClose = () => {},
}) => {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [activityModal, setActivityModal] = useState(false);
  const [openTestimonial, setOpenTestimonial] = useState(false);
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="testimonial-list">
        <div className="col col__name">
          {width <= responseiveWidth && <p className="col-header">Name</p>}
          <p className="">
            {testimonial.name}
          </p>
        </div>
        <div className="col col__designation">
          {width <= responseiveWidth && <p className="col-header">Designation</p>}
          <p className="">
            {testimonial.designation}
          </p>
        </div>
        <div className="col col__designation">
          {width <= responseiveWidth && <p className="col-header">Title</p>}
          <p className="">
            {testimonial.title}
          </p>
        </div>
        <div className="col col__review">
          {width <= responseiveWidth && <p className="col-header">Review</p>}
          <div className="review-text">
            <p>
              {testimonial.review}
            </p>
          </div>
        </div>
        <div className="col col__sort">
          {width <= responseiveWidth && <p className="col-header">Sort Order</p>}
          <div className="order-container">
            <p>
              {testimonial.sort}
            </p>
          </div>
        </div>
        <div className="col col__status">
          {width <= responseiveWidth && <p className="col-header">Status</p>}
          <div className="status-container">
            <p className={`status status__${testimonial.status}`}>
              {getStatusActive(testimonial.status)}
            </p>
          </div>
        </div>
        <div className="col col__action-btn">
          {width <= responseiveWidth && <p className="col-header">Actions</p>}
          <div className="action-btn-section">
            <IconButton onClick={() => setOpenTestimonial(true)}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7762 3.40093C17.1653 3.66266 17.4864 4.01351 17.7127 4.42432C17.939 4.83513 18.0639 5.29402 18.0771 5.76284C18.0903 6.23166 17.9914 6.69686 17.7887 7.11976C17.5859 7.54265 17.2851 7.91103 16.9112 8.19427L9.48564 16.047C9.13848 16.3862 8.7032 16.6213 8.22919 16.7255L5.31738 17.2234C5.0928 17.2793 4.85777 17.2778 4.6339 17.2191C4.41004 17.1604 4.20452 17.0464 4.03625 16.8875C3.86798 16.7286 3.74235 16.53 3.67092 16.3098C3.59949 16.0897 3.58454 15.8551 3.62746 15.6277L3.96199 12.6971C4.04711 12.2205 4.2562 11.7747 4.56823 11.4045L11.9938 3.5517C12.2548 3.164 12.6042 2.84392 13.0133 2.61784C13.4223 2.39177 13.8793 2.26619 14.3464 2.25146C14.8135 2.23674 15.2774 2.33328 15.6999 2.53314C16.1224 2.733 16.4913 3.03044 16.7762 3.40093Z" stroke="#8899B4" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.6823 9.51765C14.4609 9.39669 13.3142 8.87362 12.4222 8.03052C11.5303 7.18743 10.9434 6.07203 10.7539 4.85938" stroke="#8899B4" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.71289 20.1875H18.9917" stroke="#8899B4" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </IconButton>
            <IconButton onClick={() => setActivityModal(!activityModal)} disabled>
              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33935 4.65589C10.1368 4.41755 11.0168 4.26172 11.9885 4.26172C16.3793 4.26172 19.936 7.81839 19.936 12.2092C19.936 16.6001 16.3793 20.1567 11.9885 20.1567C7.59768 20.1567 4.04102 16.6001 4.04102 12.2092C4.04102 10.5776 4.53602 9.05588 5.37935 7.79088" stroke="#8899B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.20312 4.87536L10.8523 1.83203" stroke="#8899B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.20312 4.875L11.2923 7.13" stroke="#8899B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.9883 11V13.5575L13.2716 14.8408" stroke="#8899B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

            </IconButton>
            <IconButton onClick={() => setDeleteModal(!openDeleteModal)}>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9883 4.18488C12.3982 3.92064 9.7933 3.78516 7.19578 3.78516C5.65295 3.78536 4.11106 3.86553 2.57607 4.02538L0.988281 4.18584" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.26953 3.37626L5.4405 2.32794C5.56566 1.56789 5.65905 1 6.97322 1H9.01029C10.3254 1 10.4263 1.59959 10.5431 2.33659L10.714 3.37626" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.3155 6.7119L12.8102 14.7679C12.7242 16.0238 12.6542 17.0001 10.4845 17.0001H5.49118C3.32145 17.0001 3.24952 16.0238 3.16639 14.7679L2.66016 6.71094" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </IconButton>
          </div>
        </div>
      </div>
      <DeleteTestimonialModal
        openDeleteModal={openDeleteModal}
        setDeleteModal={setDeleteModal}
        testimonial={testimonial}
        setShowAlertMessage={setShowAlertMessage}
        setAlertMessage={setAlertMessage}
        setAlertHeaderMessage={setAlertHeaderMessage}
        handleClose={handleClose}
      />
      <TestimonialActivityModal activityModal={activityModal} setActivityModal={setActivityModal} testimonial={testimonial} />
      {openTestimonial && (
      <CreateTestimonial
        openTestimonialModal={openTestimonial}
        handleClose={(actionPerformed = false) => {
          setOpenTestimonial(false);
          if (actionPerformed) {
            handleClose();
          }
        }}
        title="Update"
        existingTestimonial={testimonial}
        setAlertMessage={setAlertMessage}
        setShowAlertMessage={setShowAlertMessage}
        setAlertHeaderMessage={setAlertHeaderMessage}
      />
      )}

    </>
  );
};

export default TestimonialArray;
