import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircleSpinner = ({
  width = '28px',
  height = '28px',
}) => (
  <Box className="spinner-circle" sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress style={{ width, height }} />
  </Box>
);

export default CircleSpinner;
