import { loadImage, prepareIcon } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/background.svg';

import Package from '../../resources/images/package.svg';
import PackageHighlighted from '../../resources/images/packageHighlighted.svg';
import BoxIcon from '../../resources/images/box.svg';
import BoxIconHighlighted from '../../resources/images/boxHighlighted.svg';
import Pin from '../../resources/images/pin.svg';
import PinHighlighted from '../../resources/images/pinHighlighted.svg';
import Tesseract from '../../resources/images/tesseract.svg';
import TesseractHighlighted from '../../resources/images/tesseractHighlighted.svg';
import Circle from '../../resources/images/circle.svg';
import CircleHighlighted from '../../resources/images/circleHighlighted.svg';
import Polygon from '../../resources/images/polygon-icon.svg';
import PolygonHighlighted from '../../resources/images/polygonHighlighted.svg';
import Square from '../../resources/images/square.svg';
import SquareHighlighted from '../../resources/images/squareHighlighted.svg';
import Star from '../../resources/images/star.svg';
import StarHighlighted from '../../resources/images/starHighlighted.svg';
import Infinity from '../../resources/images/infinity.svg';
import InfinityHighlighted from '../../resources/images/infinityHighlighted.svg';
import Diamond from '../../resources/images/diamond.svg';
import DiamondHighlighted from '../../resources/images/diamondHighlighted.svg';
import Heart from '../../resources/images/heart.svg';
import HeartHighlighted from '../../resources/images/heartHighlighted.svg';
import Clover from '../../resources/images/clover.svg';
import CloverHighlighted from '../../resources/images/cloverHighlighted.svg';
import Flower from '../../resources/images/flower.svg';
import FlowerHighlighted from '../../resources/images/flowerHighlighted.svg';
import Compass from '../../resources/images/compass.svg';
import CompassHighlighted from '../../resources/images/compassHighlighted.svg';
import Sun from '../../resources/images/sun.svg';
import SunHighlighted from '../../resources/images/sunHighlighted.svg';
import Point from '../../resources/images/point.svg';
import PointHighlighted from '../../resources/images/pointHighlighted.svg';
import MapArrow from '../../resources/images/map-arrow.svg';
import MapArrowHighlighted from '../../resources/images/map-arrowHighlighted.svg';

import Baby from '../../resources/images/Baby.svg';
import BabyHighlighted from '../../resources/images/BabyHighlighted.svg';
import Girl from '../../resources/images/Girl.svg';
import GirlHighlighted from '../../resources/images/GirlHighlighted.svg';
import Boy from '../../resources/images/Boy.svg';
import BoyHighlighted from '../../resources/images/BoyHighlighted.svg';
import Mother from '../../resources/images/Mother.svg';
import MotherHighlighted from '../../resources/images/MotherHighlighted.svg';
import Father from '../../resources/images/Father.svg';
import FatherHighlighted from '../../resources/images/FatherHighlighted.svg';
import Grandpa from '../../resources/images/Grandpa.svg';
import GrandpaHighlighted from '../../resources/images/GrandpaHighlighted.svg';
import Grandma from '../../resources/images/Grandma.svg';
import GrandmaHighlighted from '../../resources/images/GrandmaHighlighted.svg';
import Backpack from '../../resources/images/Backpack.svg';
import BackpackHighlighted from '../../resources/images/BackpackHighlighted.svg';
import Handbag from '../../resources/images/Handbag.svg';
import HandbagHighlighted from '../../resources/images/HandbagHighlighted.svg';
import CarryOn from '../../resources/images/CarryOn.svg';
import CarryOnHighlighted from '../../resources/images/CarryOnHighlighted.svg';
import Suitcase from '../../resources/images/Suitcase.svg';
import SuitcaseHighlighted from '../../resources/images/SuitcaseHighlighted.svg';
import HomeIcon from '../../resources/images/Home.svg';
import HomeIconHighlighted from '../../resources/images/HomeHighlighted.svg';
import Car from '../../resources/images/Car.svg';
import CarHighlighted from '../../resources/images/CarHighlighted.svg';
import Bicycle from '../../resources/images/Bicycle.svg';
import BicycleHighlighted from '../../resources/images/BicycleHighlighted.svg';
import Bike from '../../resources/images/Bike.svg';
import BikeHighlighted from '../../resources/images/BikeHighlighted.svg';
import Wheelchair from '../../resources/images/Wheelchair.svg';
import WheelchairHighlighted from '../../resources/images/WheelchairHighlighted.svg';

import Jerry from '../../resources/images/jerry.svg';
import JerryHighlighted from '../../resources/images/jerryHighlighted.svg';
import Spyro from '../../resources/images/spyro.svg';
import SpyroHighlighted from '../../resources/images/spyroHighlighted.svg';
import Finn from '../../resources/images/finn.svg';
import FinnHighlighted from '../../resources/images/finnHighlighted.svg';
import Jake from '../../resources/images/jake.svg';
import JakeHighlighted from '../../resources/images/jakeHighlighted.svg';
import Marceline from '../../resources/images/marceline.svg';
import MarcelineHighlighted from '../../resources/images/marcelineHighlighted.svg';
import Bubblegum from '../../resources/images/bubblegum.svg';
import BubblegumHighlighted from '../../resources/images/bubblegumHighlighted.svg';
import Brave from '../../resources/images/brave.svg';
import BraveHighlighted from '../../resources/images/braveHighlighted.svg';
import Grinch from '../../resources/images/grinch.svg';
import GrinchHighlighted from '../../resources/images/grinchHighlighted.svg';
import NinjaTurtle from '../../resources/images/ninjaTurtle.svg';
import NinjaTurtleHighlighted from '../../resources/images/ninjaTurtleHighlighted.svg';
import Oggy from '../../resources/images/oggy.svg';
import OggyHighlighted from '../../resources/images/oggyHighlighted.svg';
import MortySmith from '../../resources/images/morthySmith.svg';
import MortySmithHighlighted from '../../resources/images/morthySmithHighlighted.svg';
import Rick from '../../resources/images/rick.svg';
import RickHighlighted from '../../resources/images/rickHighlighted.svg';
import Groot from '../../resources/images/groot.svg';
import GrootHighlighted from '../../resources/images/grootHighlighted.svg';
import ScoobyDoo from '../../resources/images/scoobyDoo.svg';
import ScoobyDooHighlighted from '../../resources/images/scoobyDooHighlighted.svg';
import Daphne from '../../resources/images/daphne.svg';
import DaphneHighlighted from '../../resources/images/daphneHighlighted.svg';
import Fred from '../../resources/images/fred.svg';
import FredHighlighted from '../../resources/images/fredHighlighted.svg';
import Shaggy from '../../resources/images/shaggy.svg';
import ShaggyHighlighted from '../../resources/images/shaggyHighlighted.svg';
import Velma from '../../resources/images/velma.svg';
import VelmaHighlighted from '../../resources/images/velmaHighlighted.svg';

import Plane from '../../resources/images/plane.svg';
import PlaneHighlighted from '../../resources/images/planeHighlighted.svg';
import Boat from '../../resources/images/boat.svg';
import BoatHighlighted from '../../resources/images/boatHighlighted.svg';
import Rocket from '../../resources/images/rocket.svg';
import RocketHighlighted from '../../resources/images/rocketHighlighted.svg';
import Jetski from '../../resources/images/jetski.svg';
import JetskiHighlighted from '../../resources/images/jetskiHighlighted.svg';
import Truck from '../../resources/images/truck.svg';
import TruckHighlighted from '../../resources/images/truckHighlighted.svg';
import GarbageTruck from '../../resources/images/garbageTruck.svg';
import GarbageTruckHighlighted from '../../resources/images/garbageTruckHighlighted.svg';
import Police from '../../resources/images/police.svg';
import PoliceHighlighted from '../../resources/images/policeHighlighted.svg';
import Forklift from '../../resources/images/forklift.svg';
import ForkliftHighlighted from '../../resources/images/forkliftHighlighted.svg';
import SemiTruck from '../../resources/images/semiTruck.svg';
import SemiTruckHighlighted from '../../resources/images/semiTruckHighlighted.svg';
import Carpool from '../../resources/images/carpool.svg';
import CarpoolHighlighted from '../../resources/images/carpoolHighlighted.svg';
import Convertible from '../../resources/images/convertible.svg';
import ConvertibleHighlighted from '../../resources/images/convertibleHighlighted.svg';
import Crane from '../../resources/images/crane.svg';
import CraneHighlighted from '../../resources/images/craneHighlighted.svg';
import F1car from '../../resources/images/f1car.svg';
import F1carHighlighted from '../../resources/images/f1carHighlighted.svg';
import Hatchbak from '../../resources/images/hatchbak.svg';
import HatchbakHighlighted from '../../resources/images/hatchbakHighlighted.svg';
import Roadroller from '../../resources/images/roadRoller.svg';
import RoadrollerHighlighted from '../../resources/images/roadRollerHighlighted.svg';
import SUV from '../../resources/images/SUV.svg';
import SUVHighlighted from '../../resources/images/SUVHighlighted.svg';

import ADR from '../../resources/images/ADR.svg';
import ADRHighlighted from '../../resources/images/ADRHighlighted.svg';
import Acura from '../../resources/images/acura.svg';
import AcuraHighlighted from '../../resources/images/acuraHighlighted.svg';
import AlfaRomeo from '../../resources/images/AlfaRomeo.svg';
import AlfaRomeoHighlighted from '../../resources/images/AlfaRomeoHighlighted.svg';
import Ferrari from '../../resources/images/Ferrari.svg';
import FerrariHighlighted from '../../resources/images/FerrariHighlighted.svg';
import Hyundai from '../../resources/images/Hyundai.svg';
import HyundaiHighlighted from '../../resources/images/HyundaiHighlighted.svg';
import Infiniti from '../../resources/images/infiniti.svg';
import InfinitiHighlighted from '../../resources/images/infinitiHighlighted.svg';
import Iveco from '../../resources/images/iveco.svg';
import IvecoHighlighted from '../../resources/images/ivecoHighlighted.svg';
import Honda from '../../resources/images/honda.svg';
import HondaHighlighted from '../../resources/images/hondaHighlighted.svg';
import Toyota from '../../resources/images/toyota.svg';
import ToyotaHighlighted from '../../resources/images/toyotaHighlighted.svg';
import Tesla from '../../resources/images/tesla.svg';
import TeslaHighlighted from '../../resources/images/teslaHighlighted.svg';
import Jeep from '../../resources/images/jeep.svg';
import JeepHighlighted from '../../resources/images/jeepHighlighted.svg';
import KIA from '../../resources/images/KIA.svg';
import KIAHighlighted from '../../resources/images/KIAHighlighted.svg';
import United from '../../resources/images/united.svg';
import UnitedHighlighted from '../../resources/images/unitedHighlighted.svg';
import Nike from '../../resources/images/nike.svg';
import NikeHighlighted from '../../resources/images/nikeHighlighted.svg';
import Liverpool from '../../resources/images/liverpool.svg';
import LiverpoolHighlighted from '../../resources/images/liverpoolHighlighted.svg';
import Barcelona from '../../resources/images/barcelone.svg';
import BarcelonaHighlighted from '../../resources/images/barceloneHighlighted.svg';
import Arsenal from '../../resources/images/arsenal.svg';
import ArsenalHighlighted from '../../resources/images/arsenalHighlighted.svg';
import Chelsea from '../../resources/images/chelsea.svg';
import ChelseaHighlighted from '../../resources/images/chelseaHighlighted.svg';
import RealMadrid from '../../resources/images/realMadrid.svg';
import RealMadridHighlighted from '../../resources/images/realMadridHighlighted.svg';
import CoastGuard from '../../resources/images/coastGuard.svg';
import CoastGuardHighlighted from '../../resources/images/coastGuardHighlighted.svg';
import Nasa from '../../resources/images/nasa.svg';
import NasaHighlighted from '../../resources/images/nasaHighlighted.svg';
import MarineCorps from '../../resources/images/marineCorps.svg';
import MarineCorpsHighlighted from '../../resources/images/marineCorpsHighlighted.svg';
import Slack from '../../resources/images/slack.svg';
import SlackHighlighted from '../../resources/images/slackHighlighted.svg';
import Koo from '../../resources/images/koo.svg';
import KooHighlighted from '../../resources/images/kooHighlighted.svg';
import Ethereum from '../../resources/images/ethereum.svg';
import EthereumHighlighted from '../../resources/images/ethereumHighlighted.svg';
import Bitcoin from '../../resources/images/bitcoin.svg';
import BitcoinHighlighted from '../../resources/images/bitcoinHighlighted.svg';
import AmongUs from '../../resources/images/amongUs.svg';
import AmongUsHighlighted from '../../resources/images/amongUsHighlighted.svg';
import Fifa18 from '../../resources/images/fifa18.svg';
import Fifa18Highlighted from '../../resources/images/fifa18Highlighted.svg';
import CallOfDuty from '../../resources/images/callOfDuty.svg';
import CallOfDutyHighlighted from '../../resources/images/callOfDutyHighlighted.svg';
import RocketDrop from '../../resources/images/RocketDrop.png';
import RocketDropHighlighted from '../../resources/images/RocketDropHighlighted.png';

export const mapIcons = {
  Package,
  PackageHighlighted,
  BoxIcon,
  BoxIconHighlighted,
  Pin,
  PinHighlighted,
  Tesseract,
  TesseractHighlighted,
  Circle,
  CircleHighlighted,
  Polygon,
  PolygonHighlighted,
  Square,
  SquareHighlighted,
  Star,
  StarHighlighted,
  Infinity,
  InfinityHighlighted,
  Diamond,
  DiamondHighlighted,
  Heart,
  HeartHighlighted,
  Clover,
  CloverHighlighted,
  Flower,
  FlowerHighlighted,
  Compass,
  CompassHighlighted,
  Sun,
  SunHighlighted,
  Point,
  PointHighlighted,
  MapArrow,
  MapArrowHighlighted,
  Baby,
  BabyHighlighted,
  Girl,
  GirlHighlighted,
  Boy,
  BoyHighlighted,
  Mother,
  MotherHighlighted,
  Father,
  FatherHighlighted,
  Grandpa,
  GrandpaHighlighted,
  Grandma,
  GrandmaHighlighted,
  Backpack,
  BackpackHighlighted,
  Handbag,
  HandbagHighlighted,
  CarryOn,
  CarryOnHighlighted,
  Suitcase,
  SuitcaseHighlighted,
  HomeIcon,
  HomeIconHighlighted,
  Car,
  CarHighlighted,
  Bicycle,
  BicycleHighlighted,
  Bike,
  BikeHighlighted,
  Wheelchair,
  WheelchairHighlighted,
  Jerry,
  JerryHighlighted,
  Spyro,
  SpyroHighlighted,
  Finn,
  FinnHighlighted,
  Jake,
  JakeHighlighted,
  Marceline,
  MarcelineHighlighted,
  Bubblegum,
  BubblegumHighlighted,
  Brave,
  BraveHighlighted,
  Grinch,
  GrinchHighlighted,
  NinjaTurtle,
  NinjaTurtleHighlighted,
  Oggy,
  OggyHighlighted,
  MortySmith,
  MortySmithHighlighted,
  Rick,
  RickHighlighted,
  Groot,
  GrootHighlighted,
  ScoobyDoo,
  ScoobyDooHighlighted,
  Daphne,
  DaphneHighlighted,
  Fred,
  FredHighlighted,
  Shaggy,
  ShaggyHighlighted,
  Velma,
  VelmaHighlighted,
  Plane,
  PlaneHighlighted,
  Boat,
  BoatHighlighted,
  Rocket,
  RocketHighlighted,
  Jetski,
  JetskiHighlighted,
  Truck,
  TruckHighlighted,
  GarbageTruck,
  GarbageTruckHighlighted,
  Police,
  PoliceHighlighted,
  Forklift,
  ForkliftHighlighted,
  SemiTruck,
  SemiTruckHighlighted,
  Carpool,
  CarpoolHighlighted,
  Convertible,
  ConvertibleHighlighted,
  Crane,
  CraneHighlighted,
  F1car,
  F1carHighlighted,
  Hatchbak,
  HatchbakHighlighted,
  Roadroller,
  RoadrollerHighlighted,
  SUV,
  SUVHighlighted,
  ADR,
  ADRHighlighted,
  Acura,
  AcuraHighlighted,
  AlfaRomeo,
  AlfaRomeoHighlighted,
  Ferrari,
  FerrariHighlighted,
  Hyundai,
  HyundaiHighlighted,
  Infiniti,
  InfinitiHighlighted,
  Iveco,
  IvecoHighlighted,
  Honda,
  HondaHighlighted,
  Toyota,
  ToyotaHighlighted,
  Tesla,
  TeslaHighlighted,
  Jeep,
  JeepHighlighted,
  KIA,
  KIAHighlighted,
  United,
  UnitedHighlighted,
  Nike,
  NikeHighlighted,
  Liverpool,
  LiverpoolHighlighted,
  Barcelona,
  BarcelonaHighlighted,
  Arsenal,
  ArsenalHighlighted,
  Chelsea,
  ChelseaHighlighted,
  RealMadrid,
  RealMadridHighlighted,
  CoastGuard,
  CoastGuardHighlighted,
  Nasa,
  NasaHighlighted,
  MarineCorps,
  MarineCorpsHighlighted,
  Slack,
  SlackHighlighted,
  Koo,
  KooHighlighted,
  Ethereum,
  EthereumHighlighted,
  Bitcoin,
  BitcoinHighlighted,
  AmongUs,
  AmongUsHighlighted,
  Fifa18,
  Fifa18Highlighted,
  CallOfDuty,
  CallOfDutyHighlighted,
  RocketDrop,
  RocketDropHighlighted,
  basic: {
    Package,
    BoxIcon,
    Pin,
    Tesseract,
    Circle,
    Polygon,
    Square,
    Star,
    Infinity,
    Diamond,
    Heart,
    Clover,
    Flower,
    Compass,
    Sun,
    Point,
    MapArrow,
  },
  home: {
    Baby,
    Girl,
    Boy,
    Mother,
    Father,
    Grandpa,
    Grandma,
    Backpack,
    Handbag,
    CarryOn,
    Suitcase,
    HomeIcon,
    Car,
    Bicycle,
    Bike,
    Wheelchair,
  },
  character: {
    Jerry,
    Spyro,
    Finn,
    Jake,
    Marceline,
    Bubblegum,
    Brave,
    Grinch,
    NinjaTurtle,
    Oggy,
    MortySmith,
    Rick,
    Groot,
    ScoobyDoo,
    Daphne,
    Fred,
    Shaggy,
    Velma,
  },
  vehicles: {
    Plane,
    Boat,
    Rocket,
    Jetski,
    Truck,
    GarbageTruck,
    Police,
    Forklift,
    SemiTruck,
    Carpool,
    Convertible,
    Crane,
    F1car,
    Hatchbak,
    Roadroller,
    SUV,
  },
  others: {
    ADR,
    Acura,
    AlfaRomeo,
    Ferrari,
    Hyundai,
    Infiniti,
    Iveco,
    Honda,
    Toyota,
    Tesla,
    Jeep,
    KIA,
    United,
    Nike,
    Liverpool,
    Barcelona,
    Arsenal,
    Chelsea,
    RealMadrid,
    CoastGuard,
    Nasa,
    MarineCorps,
    Slack,
    Koo,
    Ethereum,
    Bitcoin,
    AmongUs,
    Fifa18,
    CallOfDuty,
    RocketDrop,
  },
};

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : ((category && category.includes('Highlighted')) ? 'PackageHighlighted' : 'Package'));

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    if (typeof mapIcons[category] !== 'object' && mapIcons[category] !== null) {
      results.push(loadImage(mapIcons[category]).then((icon) => {
        mapImages[`${category}`] = prepareIcon(background, icon);
      }));
    }
    await Promise.all(results);
  }));
};
