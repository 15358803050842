import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';

import { nativePostMessage } from '../../../common/components/NativeInterface';
import { sessionActions } from '../../../store';

import { ReactComponent as UserIcon } from '../../../resources/images/user-dropdown/user-icon.svg';
import { ReactComponent as BellIcon } from '../../../resources/images/user-dropdown/bell-icon.svg';
import { ReactComponent as DeviceIcon } from '../../../resources/images/user-dropdown/device-icon.svg';
import { ReactComponent as GeofenceIcon } from '../../../resources/images/user-dropdown/geofence-icon.svg';
import { ReactComponent as OrderIcon } from '../../../resources/images/user-dropdown/order-icon.svg';
import { ReactComponent as LoginICon } from '../../../resources/images/user-dropdown/login.svg';
import { useAdministrator } from '../../../common/util/permissions';
import customFetch from '../../../common/util/customFetch';

const UserMenu = ({ open, handleClose }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const administrator = useAdministrator();
  const user = useSelector((state) => state.session.user);
  const path = location?.pathname;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
    return null;
  }, [open, dropdownRef, handleClose]);

  const handleLogout = async () => {
    window.localStorage.removeItem('wasFilterOpenedOnLogin');
    window.localStorage.removeItem('nonActiveTrackerModel');
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      window.localStorage.setItem('modalShow', false);
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await customFetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }
    window.localStorage.removeItem('filter');
    window.localStorage.removeItem('filterSort');
    await customFetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    window.location = '/';
    localStorage.removeItem('auth-token');
    dispatch(sessionActions.updateAuthToken(null));
    dispatch(sessionActions.updateUser(null));
  };
  const currentUser = useSelector((state) => state.session.user);

  const profileLink = administrator ? `/settings/user/${user.id}` : '/settings/profile';
  const notificationsLink = administrator ? '/reports/event' : '/settings/alerts?viewAll=1';
  const devicesLink = administrator ? '/settings/devices' : '/devices';
  const ordersLink = administrator ? '/settings/all-orders' : '/settings/orders';
  // const getUserType = (user) => (user ? (user.administrator ? 'Admin' : (user.superAdministrator ? 'Super Admin' : 'User')) : '');
  if (open) {
    return (
      <div className="user-menu" ref={dropdownRef}>
        <div className="user-menu__container">
          <div className="user-menu__container__header">
            <div className="user-info">
              <img src={currentUser.profileImageUrl ? `${process.env.REACT_APP_BUCKET_URL}/${currentUser.profileImageUrl}` : '/default-dp.png'} alt="user-pic" />
              <p>{user?.fullName}</p>
            </div>
            {/* <Button variant="container">{getUserType(user)}</Button> */}
          </div>
          <div className="divider" />
          <div className="user-menu__container__body">
            {/* <p className="title">Account</p> */}
            <ul className="list">
              <li className={path === '/settings/profile' || path === `/settings/user/${user.id}` ? 'active' : ''}>
                <Link to={profileLink} onClick={handleClose}>
                  <UserIcon />
                  {' '}
                  <span>Account</span>
                </Link>
              </li>
              <li className={path === '/settings/devices' || path === '/devices' ? 'active' : ''}>
                <Link to={devicesLink} onClick={handleClose}>
                  <DeviceIcon />
                  <span>{administrator ? 'Devices' : 'Dashboard'}</span>
                </Link>
              </li>
              {/* <li className={path === '/reports/event' || path === '/notifications' ? 'active' : ''}>
                <Link to={notificationsLink} onClick={handleClose}>
                  <BellIcon />
                  {' '}
                  <span>Notifications</span>
                </Link>
              </li> */}
              {/* <li className={path === '/geofences' ? 'active' : ''}>
                <Link to="/geofences" onClick={handleClose}>
                  <GeofenceIcon />
                  <span>Geofence</span>
                </Link>
              </li> */}
              { administrator && (
                <li className={path === '/settings/users' ? 'active' : ''}>
                  <Link to="/settings/users" onClick={handleClose}>
                    <UserIcon />
                    {' '}
                    <span>Users</span>
                  </Link>

                </li>
              )}
              {/* <li className={path === '/settings/all-orders' || path === '/settings/orders' ? 'active' : ''}>
                <Link to={ordersLink} onClick={handleClose}>
                  <OrderIcon />
                  <span>Orders</span>
                </Link>
              </li> */}
              {user && administrator && (
              <li className={path === '/reports/route' ? 'active' : ''}>
                <Link to="/reports/route" onClick={handleClose}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.667 4.49992V6.11325C14.667 7.16658 14.0003 7.83325 12.947 7.83325H10.667V3.17325C10.667 2.43325 11.2737 1.83325 12.0137 1.83325C12.7403 1.83992 13.407 2.13325 13.887 2.61325C14.367 3.09992 14.667 3.76659 14.667 4.49992Z" stroke="#8899B4" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.33301 5.16659V14.4999C1.33301 15.0533 1.95967 15.3666 2.39967 15.0333L3.53967 14.1799C3.80634 13.9799 4.17967 14.0066 4.41967 14.2466L5.52634 15.3599C5.78634 15.6199 6.21301 15.6199 6.47301 15.3599L7.59301 14.2399C7.82634 14.0066 8.19968 13.9799 8.45968 14.1799L9.59968 15.0333C10.0397 15.3599 10.6663 15.0466 10.6663 14.4999V3.16659C10.6663 2.43325 11.2663 1.83325 11.9997 1.83325H4.66634H3.99967C1.99967 1.83325 1.33301 3.02659 1.33301 4.49992V5.16659Z" stroke="#8899B4" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16699 7.16675H7.83366" stroke="#8899B4" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  {' '}
                  <span>Reports</span>
                </Link>

              </li>
              )}
            </ul>
          </div>
          <div className="divider" />
          <div className="user-menu__container__footer">
            <Button className="logout-btn" onClick={handleLogout}>
              <LoginICon />
              {' '}
              <span>Logout</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default UserMenu;
