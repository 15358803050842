import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import PageLayout from '../../../common/components/PageLayout';
import SettingsMenu from '../../components/SettingsMenu';
import SettingsHeader from './FAQHeader/SettingsHeader';
import SettingsSearch from './FAQHeader/SettingsSearch';
import SettingsPagination from '../../../common/components/Pagination/SettingsPagination';
import FAQArray from './FAQArray';
import { useEffectAsync } from '../../../reactHelper';
import customFetch from '../../../common/util/customFetch';
import AlertMessage from '../../../common/components/AlertMessage';

const perPage = 10;
const FAQPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [filteredPaginatedFaqs, setFilteredPaginatedFaqs] = useState([]);
  const [dateForUpdate, setDateForUpdate] = useState(new Date());
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertHeaderMessage, setAlertHeaderMessage] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    status: [],
    category: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [addFAQ, setAddFAQ] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const { width } = useWindowDimensions();
  const responseiveWidth = 1025;

  useEffectAsync(async () => {
    const [categoryResponse, faqResponse] = await Promise.all([customFetch('/api/categories/all'), customFetch('/api/faqs/all')]);
    if (faqResponse.ok) {
      const data = await faqResponse.json();
      setFaqs(data);
      setFilteredFaqs(data);
    }
    if (categoryResponse.ok) {
      const originalCategoriesData = await categoryResponse.json();
      const categoriesData = originalCategoriesData.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategories(categoriesData);
      setOriginalCategories(originalCategoriesData);
    }
  }, [dateForUpdate]);

  const keysToSearch = ['question', 'answer'];

  useEffect(() => {
    setCurrentPage(1);
    if (faqs.length > 0 && ((filter.search !== '') || (filter.status.length > 0) || (filter.category.length > 0))) {
      const filteration = faqs.filter((item) => (
        filter.search !== '' ? keysToSearch.some((key) => item[key].toLowerCase().includes(filter.search.toLowerCase())) : true
      ) && (
        filter.status.length > 0 ? filter.status.findIndex((status) => item.status.toLowerCase() === status.toLowerCase()) !== -1 : true
      ) && (
        filter.category.length > 0 ? filter.category.findIndex((category) => item.categoryId === category) !== -1 : true
      ));
      setFilteredFaqs(filteration);
    } else {
      setFilteredFaqs(faqs);
    }
  }, [filter, faqs]);

  const paginate = (items, perPage, pageNumber) => {
    const start = (pageNumber - 1) * perPage;
    return items.slice(start, start + perPage);
  };

  useEffect(() => {
    const paginatedFaqs = paginate(filteredFaqs, perPage, currentPage);
    setFilteredPaginatedFaqs(paginatedFaqs);
  }, [filteredFaqs, currentPage]);

  const handleClose = () => {
    setDateForUpdate(new Date());
  };

  const getCategoryName = (id) => {
    const category = categories.find((item) => item.value === id);
    return category ? category.label : '';
  };

  const convertToCSV = (data = []) => Papa.unparse(data);
  const exportData = async () => {
    const categoryResponse = await customFetch('/api/categories/all');
    if (categoryResponse.ok) {
      const originalCategoriesData = await categoryResponse.json();
      const categoriesData = originalCategoriesData.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategories(categoriesData);
      setOriginalCategories(originalCategoriesData);
    }
    const response = await customFetch('/api/faqs/all');
    if (response.ok) {
      const data = await response.json();
      if (data.length > 0) {
        const filteredData = data.filter((item) => (
          filter.search !== '' ? keysToSearch.some((key) => item[key].toLowerCase().includes(filter.search.toLowerCase())) : true
        ) && (
          filter.status.length > 0 ? filter.status.findIndex((status) => item.status.toLowerCase() === status.toLowerCase()) !== -1 : true
        ) && (
          filter.category.length > 0 ? filter.category.findIndex((category) => item.categoryId === category) !== -1 : true
        ));
        if (filteredData.length > 0) {
          const dataToExport = filteredData.map((item) => ({
            Question: item.question,
            Answer: item.answer,
            Category: getCategoryName(item.categoryId),
            Sort: item.sort,
            Status: item.status,
          }));
          const csv = convertToCSV(dataToExport);
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          saveAs(url, 'Faqs.csv');
        }
      }
    }
  };

  return (
    <div>
      <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'Faqs']} testimonialsPage>
        <Container className="testimonial-settings-container">
          <div className="admin-testimonial-page">
            <SettingsHeader
              addCategory={addCategory}
              setAddCategory={setAddCategory}
              addFAQ={addFAQ}
              setAddFAQ={setAddFAQ}
              title="Create"
              setAlertMessage={setAlertMessage}
              setShowAlertMessage={setShowAlertMessage}
              setAlertHeaderMessage={setAlertHeaderMessage}
              handleClose={handleClose}
              exportDisabled={filteredPaginatedFaqs.length === 0}
              exportData={exportData}
              categories={categories}
              originalCategories={originalCategories}
            />
            <div className="testimonial-body">
              <SettingsSearch setFilter={setFilter} filter={filter} categories={categories} />
              {width > responseiveWidth && (
              <div className="table-header">
                <p>Question</p>
                <p className="review">Answer</p>
                <p>Category</p>
                <p>Sort</p>
                <p>Status</p>
                <p className="hidden">Actions</p>
              </div>
              )}
              <div className="table-body">
                {filteredPaginatedFaqs.length === 0 ? (
                  <div className="no-tracker-found">
                    <svg width="91" height="87" viewBox="0 0 91 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M89.372 36.4136L84.9212 55.3978C81.1062 71.7932 73.567 78.424 59.397 77.0615C57.1262 76.8798 54.6737 76.4711 52.0395 75.8353L44.4095 74.0186C25.4708 69.5223 19.612 60.1665 24.0628 41.1823L28.5137 22.1528C29.422 18.2923 30.512 14.9315 31.8745 12.1611C37.1883 1.17026 46.2262 -1.78182 61.3953 1.80609L68.9799 3.57734C88.0095 8.02818 93.8229 17.4294 89.372 36.4136Z" fill="#B7D7FF" />
                      <path d="M59.3895 77.0645C56.5737 78.972 53.0312 80.5616 48.7166 81.9695L41.5408 84.3311C23.5104 90.1445 14.0183 85.2849 8.15953 67.2545L2.34619 49.3149C-3.46714 31.2845 1.34703 21.747 19.3774 15.9336L26.5533 13.572C28.4154 12.9816 30.1866 12.482 31.867 12.1641C30.5045 14.9345 29.4145 18.2953 28.5062 22.1557L24.0554 41.1853C19.6045 60.1695 25.4633 69.5253 44.402 74.0216L52.032 75.8382C54.6662 76.4741 57.1187 76.8828 59.3895 77.0645Z" fill="#1A74E2" fillOpacity="0.4" />
                      <path d="M70.4298 36.7326C70.1573 36.7326 69.8848 36.6872 69.5669 36.6418L47.5398 31.0556C45.7232 30.6014 44.6332 28.7393 45.0873 26.9226C45.5415 25.106 47.4036 24.016 49.2202 24.4701L71.2473 30.0564C73.064 30.5106 74.154 32.3726 73.6998 34.1893C73.3365 35.6881 71.9286 36.7326 70.4298 36.7326Z" fill="#1A74E2" />
                      <path d="M57.1307 52.0853C56.8582 52.0853 56.5857 52.0399 56.2678 51.9945L43.0515 48.6337C41.2349 48.1795 40.1449 46.3174 40.599 44.5008C41.0532 42.6841 42.9153 41.5941 44.732 42.0483L57.9482 45.4091C59.7649 45.8633 60.8549 47.7253 60.4007 49.542C60.0374 51.0862 58.6749 52.0853 57.1307 52.0853Z" fill="#1A74E2" />
                    </svg>

                    <p>No FAQs Found</p>
                  </div>
                ) : filteredPaginatedFaqs.map((faq) => (
                  <FAQArray
                    key={faq.id}
                    responseiveWidth={responseiveWidth}
                    faq={faq}
                    setAlertMessage={setAlertMessage}
                    setShowAlertMessage={setShowAlertMessage}
                    setAlertHeaderMessage={setAlertHeaderMessage}
                    handleClose={handleClose}
                    getCategoryName={getCategoryName}
                    categories={categories}
                  />
                ))}
              </div>
            </div>
          </div>
          {filteredPaginatedFaqs.length > 0 && (
          <SettingsPagination
            items={filteredFaqs}
            perPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          )}
        </Container>
      </PageLayout>
      {showAlertMessage && (
        <div className="location-alert">
          <AlertMessage
            message={alertMessage}
            alertHeaderMessage={alertHeaderMessage}
            show={showAlertMessage}
            onClose={() => {
              setShowAlertMessage(false);
              setAlertMessage('');
              setAlertHeaderMessage('');
            }}
            vertical="bottom"
            duration={3000}
          />
        </div>
      )}
    </div>
  );
};

export default FAQPage;
