import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import CartDrawer from './CartDrawer';

const UserCart = () => {
  const order = useSelector((state) => state.cart.order);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const toggleCartOpen = () => setIsCartOpen((prevState) => !prevState);
  // const cartCounter = order && order.quantity > 0 && order.orderItems.length > 0 ? (order.orderItems.length + (order.orderSubscription ? 1 : 0)) : 0;
  const cartCounter = order && order.quantity > 0 ? order.quantity : 0;
  return (
    <div className="user-cart">
      {cartCounter > 0 && (
        <IconButton className="cart-counter" onClick={toggleCartOpen}>{cartCounter}</IconButton>
      )}
      <svg className="cart-icon" onClick={toggleCartOpen} xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26" fill="none">
        <g clipPath="url(#clip0_8204_68919)">
          <path d="M2.09277 1.61938L3.38062 1.88477C4.43853 2.10277 5.23426 3.00695 5.34161 4.11302L5.52914 6.04514M5.52914 6.04514L6.74242 16.4625C6.89045 17.7335 7.93699 18.6901 9.17948 18.6901H20.2163C22.1873 18.6901 23.9055 17.308 24.3835 15.3378L26.1427 8.08768C26.3944 7.05016 25.6327 6.04514 24.5948 6.04514H5.52914Z" stroke="#021021" strokeWidth="1.8" strokeLinecap="round" />
          <path d="M15.5927 14.8967H10.6836" stroke="#021021" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.0699 25.0127C11.0866 25.0127 11.9108 24.1635 11.9108 23.116C11.9108 22.0684 11.0866 21.2192 10.0699 21.2192C9.05321 21.2192 8.229 22.0684 8.229 23.116C8.229 24.1635 9.05321 25.0127 10.0699 25.0127Z" fill="#021021" />
          <path d="M21.1156 25.0127C22.1323 25.0127 22.9565 24.1635 22.9565 23.116C22.9565 22.0684 22.1323 21.2192 21.1156 21.2192C20.0989 21.2192 19.2747 22.0684 19.2747 23.116C19.2747 24.1635 20.0989 25.0127 21.1156 25.0127Z" fill="#021021" />
        </g>
        <defs>
          <clipPath id="clip0_8204_68919">
            <rect width="27" height="25.29" fill="white" transform="translate(0.865479 0.35498)" />
          </clipPath>
        </defs>
      </svg>
      <CartDrawer open={isCartOpen} toggleCartOpen={toggleCartOpen} />
    </div>
  );
};

export default UserCart;
