import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'users',
  initialState: {
    items: {},
    subscriptions: [],
    permittedNotifications: [],
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    setUserSubscriptions(state, action) {
      state.subscriptions = action.payload;
    },
    setUserPermittedNotifications(state, action) {
      state.permittedNotifications = action.payload;
    },
  },
});

export { actions as usersActions };
export { reducer as usersReducer };
