/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

import { useAdministrator } from '../../../common/util/permissions';

function getClasses(path, link) {
  if (path === link) {
    return 'router-link-active';
  } if (path.includes(link) && link !== '/') {
    return 'router-link-active';
  }
  return ' ';
}
const DrawerMenu = ({ open, toggleDrawer }) => {
  const user = useSelector((state) => state.session.user);
  const administrator = useAdministrator();
  const [showNavLinks, setShowNavLinks] = useState(true);
  const location = useLocation();
  const path = location?.pathname;

  useEffect(() => {
    const links = () => !(path === '/devices' || path.includes('/settings') || path.includes('/reports') || path.includes('/replay') || path.includes('/geofences') || path.includes('/orders') || path.includes('/notifications'));
    setShowNavLinks(links);
  }, [path]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      sx={{
        '& .MuiPaper-root': {
          width: '159px',
          marginTop: '0px',
          backgroundColor: 'transparent !important',
          color: '#021021 !important',
          boxShadow: 'none !important',
        },
      }}
    >
      <div className="drawer">
        <ul className="drawer__nav-list">
          {
            showNavLinks &&
            <li onClick={toggleDrawer}><Link className={getClasses(path, 'activate-pom')} to="/activate-pom">Activate</Link></li>
          }
          {
            showNavLinks && (
              <li onClick={toggleDrawer}><Link className={getClasses(path, 'shop')} to="/shop">Order</Link></li>
            )
          }
          {
            showNavLinks &&
            <li onClick={toggleDrawer}><Link className={getClasses(path, 'book-demo')} to="/book-demo">Book Demo</Link></li>
          }
          {
            user && administrator &&
            <li onClick={toggleDrawer}><Link className={getClasses(path, 'reports')} to="/reports/route">Reports</Link></li>
          }
        </ul>
        <svg className="cross-icon" onClick={toggleDrawer} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.00195 0.958008L12.0015 10.8342" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.00044 10.8342L12 0.958008" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
