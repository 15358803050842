import React from 'react';
import Lottie from 'react-lottie-player';
import Confetti from '../resources/loader.json';

const WaitingStatus = () => (
  <div className="waiting-animation">

    <Lottie
      animationData={Confetti}
      loop
      play
      style={{ width: 300, height: 300 }}
    />
    <p>Hold On!</p>
    <span>Please hold on for a moment while we process your POMs  with subscription. You&apos;ll be able to view your POMs shortly. Do not refresh or close the page.</span>

  </div>
);
export default WaitingStatus;
