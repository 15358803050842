import React, { useState } from 'react';
import {
  Button, Grid, IconButton, Modal,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CircleSpinner from '../../../../CircleSpinner/CircleSpinner';
import CustomSelect from '../../../../homePage/footer/pages/CustomSelect';
import useForm from '../../../../hooks/useForm';
import customFetch from '../../../../common/util/customFetch';
import { defaultStatusByActive, statusByActive } from '../../../../common/util/status';
import checkEmptyObject from '../../../../common/util/checkEmptyObject';

const validationMessages = {
  name: 'Name is required',
  title: 'Title is required',
  designation: 'Designation is required',
  review: 'Review is required',
  sort: 'Sort Order is required',
  status: 'Status is required',
};

const CharacterLimitMessages = {
  name: 'Name is too long',
  title: 'Title is too long',
  designation: 'Designation is retoo longquired',
  review: 'Review is too long',
};

const CharacterLimits = {
  name: 50,
  title: 100,
  designation: 50,
  review: 350,
};

const CreateTestimonial = ({
  openTestimonialModal = false,
  handleClose = () => {},
  title = 'Create',
  existingTestimonial = {},
  setAlertMessage = () => {},
  setAlertHeaderMessage = () => {},
  setShowAlertMessage = () => {},
}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    register,
    values,
    setValues,
    errors,
    setErrors,
    isFormValid,
  } = useForm({
    initialValues: {
      name: existingTestimonial?.name || '',
      title: existingTestimonial?.title || '',
      designation: existingTestimonial?.designation || '',
      review: existingTestimonial?.review || '',
      sort: existingTestimonial?.sort || '1',
      status: existingTestimonial?.status || defaultStatusByActive,
      stars: existingTestimonial?.stars || '5',
      createdAt: existingTestimonial?.createdAt || new Date(),
      updatedAt: existingTestimonial?.updatedAt || new Date(),
      ...(existingTestimonial?.id && { id: existingTestimonial?.id }),
    },
  });

  const saveTestimonial = async () => {
    if (isFormValid && checkEmptyObject(errors) && !checkEmptyObject(values)) {
      setShowSpinner(true);
      const url = existingTestimonial?.id ? `/api/testimonials/${existingTestimonial?.id}` : '/api/testimonials';
      const response = await customFetch(url, {
        method: existingTestimonial?.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values }),
      });
      const message = (
        <span>
          Your testimonial named
          {' '}
          <span style={{ color: '#1A74E2' }}>{values.name}</span>
          {' '}
          has been
          {' '}
          {`${title.toLowerCase()}d`}
          {' '}
          successfully.
        </span>
      );
      const alertHeaderMessage = `Testimonial ${title}d successfully`;
      if (response.ok) {
        setAlertHeaderMessage(alertHeaderMessage);
        setAlertMessage(message);
        setShowAlertMessage(true);
        setShowSpinner(false);
        handleClose(true);
      }
    } else {
      const updatedErrors = {};
      Object.keys(values).forEach((key) => {
        if (!values[key] && validationMessages.hasOwnProperty(key)) {
          updatedErrors[key] = validationMessages[key];
        } else if (values[key] && CharacterLimits.hasOwnProperty(key) && values[key].length > CharacterLimits[key]) {
          updatedErrors[key] = CharacterLimitMessages[key];
        }
      });
      setErrors(updatedErrors);
    }
  };

  return (
    <Modal
      open={openTestimonialModal}
      onClose={handleClose}
      classes={{ root: 'center-with-flex' }}
    >
      <div
        className="create-testimonial-modal"
      >
        <div className="modal-header">
          <p>
            {title}
            {' '}
            Testimonial
          </p>
          <IconButton
            className="cross-btn"
            onClick={handleClose}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9991 13.96L1.03906 1" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.96 1.03906L1 13.9991" stroke="#8899B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        </div>
        <div className="modal-body">
          <div className="inner-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <div className="form">
                  <TextField
                    label="Name"
                    {...register('name', {
                      validate: (v) => (v ? (v.length > CharacterLimits.name ? CharacterLimitMessages.name : null) : validationMessages.name),
                    })}
                    error={errors.name}
                    helperText={errors.name ? errors.name : ''}
                    placeholder="Tony H."
                    className={errors.name ? 'form-textfield error-field' : 'form-textfield'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="form">
                  <TextField
                    label="Designation"
                    {...register('designation', {
                      validate: (v) => (v ? (v.length > CharacterLimits.designation ? CharacterLimitMessages.designation : null) : validationMessages.designation),
                    })}
                    error={errors.designation}
                    helperText={errors.designation ? errors.designation : ''}
                    placeholder="Wholesaler"
                    className={errors.designation ? 'form-textfield error-field' : 'form-textfield'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form">
                  <TextField
                    label="Title"
                    {...register('title', {
                      validate: (v) => (v ? (v.length > CharacterLimits.title ? CharacterLimitMessages.title : null) : validationMessages.title),
                    })}
                    error={errors.title}
                    helperText={errors.title ? errors.title : ''}
                    placeholder="Easy Setup, Great Accuracy"
                    className={errors.title ? 'form-textfield error-field' : 'form-textfield'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-textarea">
                  <p className="label">
                    <span className="label-tag">Review</span>
                    <span className="info-required">*</span>
                  </p>
                  <div className={errors.review ? 'textarea error-field' : 'textarea'}>
                    <TextareaAutosize
                      required
                      {...register('review', {
                        validate: (v) => (v ? (v.length > CharacterLimits.review ? CharacterLimitMessages.review : null) : validationMessages.review),
                      })}
                      label="Review"
                      name="Review"
                      placeholder="Enter Review"
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  {(errors.review) && (<div className="text-error">{errors.review}</div>)}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomSelect
                  title="Status"
                  required
                  {...register('status', {
                    validate: (v) => (v ? null : validationMessages.status),
                  })}
                  error={errors.status}
                  helperText={errors.status ? errors.status : ''}
                  placeholder={
                    <div className="select-placeholder-text">{defaultStatusByActive}</div>
              }
                  options={statusByActive}
                />
                {(errors.status) && (<div className="text-error">{errors.status}</div>)}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className="form">
                  <TextField
                    type="number"
                    {...register('sort', {
                      validate: (v) => (v ? null : validationMessages.sort),
                    })}
                    onChange={(e) => {
                      if (e.target.value > 0 || e.target.value === '') {
                        setValues((prevState) => ({ ...prevState, sort: parseInt(e.target.value, 10) }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        setValues((prevState) => ({ ...prevState, sort: 1 }));
                      }
                    }}
                    error={errors.sort}
                    helperText={errors.sort ? errors.sort : ''}
                    label="Sort Order"
                    placeholder="1"
                    className={errors.sort ? 'form-textfield error-field' : 'form-textfield'}
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="modal-footer">
            {showSpinner ? <CircleSpinner /> : (
              <Button
                className="btn btn--contained"
                variant="contained"
                onClick={saveTestimonial}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTestimonial;
