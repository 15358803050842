import userflow from 'userflow.js';

let init = false;

export const identifyToUserFlow = (user) => {
  if (!process.env.REACT_APP_USERFLOW_TOKEN) {
    console.error('Userflow not loaded, missing token');
  }
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
  if (user) {
    userflow.identify(user.id, {
      name: user.name,
      email: user.email,
      device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
    });
  }
  init = true;
};

export const logoutUserFlow = () => init && userflow.identifyAnonymous();
