import React from 'react';

import { Link } from 'react-router-dom';

const Logo = () => (
  <div className="header-logo">
    <Link to="/">
      <svg width="151" height="51" viewBox="0 0 151 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1490_7220)">
          <path d="M31.1458 15.3641C31.1458 22.9799 26.9527 30.0005 15.9727 30.0005C13.5781 30.0005 10.9157 29.6678 7.788 28.9403V40.7288C7.78592 43.3121 0 43.3783 0 40.7288V3.57773C0 1.9223 4.52535 0.0664062 13.7754 0.0664062C24.7575 0.0664062 31.1458 5.49563 31.1458 15.3641ZM23.2269 15.3001C23.2269 9.7365 20.0328 6.75632 13.9104 6.75632C11.8481 6.75632 9.71735 7.02292 7.788 7.61607V22.7815C10.4505 23.4449 12.7121 23.7756 14.575 23.7756C20.9632 23.7756 23.2269 20.132 23.2269 15.298V15.3001Z" fill="#1A74E2" />
          <path d="M66.549 42.2518H41.4612C40.4623 42.2518 39.9971 40.7947 39.9971 39.3377V3.31303C39.9971 1.85601 40.5287 0.53125 41.4612 0.53125H67.1492C70.2768 0.53125 66.8833 7.35343 63.7557 7.35343H47.783V17.751H61.8907C64.4846 17.751 64.4846 24.5711 61.8907 24.5711H47.7165V35.4337H66.549C69.2114 35.4337 69.2114 42.2538 66.549 42.2538V42.2518Z" fill="#1A74E2" />
          <path d="M104.016 42.2518H78.9287C77.9298 42.2518 77.4646 40.7947 77.4646 39.3377V3.31303C77.4646 1.85601 77.9963 0.53125 78.9287 0.53125H104.617C107.744 0.53125 104.349 7.35343 101.223 7.35343H85.2505V17.751H99.3582C101.954 17.751 101.954 24.5711 99.3582 24.5711H85.182V35.4337H104.016C106.679 35.4337 106.679 42.2538 104.016 42.2538V42.2518Z" fill="#1A74E2" />
          <path d="M141.349 50.3304C139.019 48.3443 137.09 45.2318 135.96 42.3178C134.496 42.5823 132.899 42.7146 131.235 42.7146C118.924 42.7146 111.603 34.7681 111.603 21.3883C111.603 8.00847 118.924 0 131.235 0C143.546 0 151 7.94647 151 21.3904C151 30.2648 147.806 36.6902 142.016 40.0671C143.546 43.0473 146.076 45.6968 148.938 47.8152C151.866 49.8013 143.214 52.1863 141.351 50.3304H141.349ZM131.235 35.8945C138.556 35.8945 142.813 30.5314 142.813 21.3904C142.813 12.2493 138.554 6.82218 131.235 6.82218C123.916 6.82218 119.788 12.1853 119.788 21.3904C119.788 30.5955 123.981 35.8945 131.235 35.8945Z" fill="#1A74E2" />
          <path d="M31.1458 15.3641C31.1458 22.9799 26.9527 30.0005 15.9727 30.0005C13.5781 30.0005 10.9157 29.6678 7.788 28.9403V40.7288C7.78592 43.3121 0 43.3783 0 40.7288V3.57773C0 1.9223 4.52535 0.0664062 13.7754 0.0664062C24.7575 0.0664062 31.1458 5.49563 31.1458 15.3641ZM23.2269 15.3001C23.2269 9.7365 20.0328 6.75632 13.9104 6.75632C11.8481 6.75632 9.71735 7.02292 7.788 7.61607V22.7815C10.4505 23.4449 12.7121 23.7756 14.575 23.7756C20.9632 23.7756 23.2269 20.132 23.2269 15.298V15.3001Z" fill="#1A74E2" />
          <path d="M13.7754 0.273076C19.2166 0.273076 23.4782 1.58337 26.4418 4.17088C29.4262 6.77285 30.9381 10.5405 30.9381 15.3641C30.9381 19.4314 29.7564 22.9014 27.5218 25.4021C24.9154 28.3182 21.0297 29.7959 15.9727 29.7959C13.549 29.7959 10.8866 29.4508 7.83577 28.7419L7.58032 28.682V40.7308C7.58032 41.884 5.70705 42.4855 3.85662 42.4855C2.87014 42.4855 1.95842 42.3181 1.28969 42.0163C0.797491 41.7931 0.209757 41.3901 0.209757 40.7308V3.57773C0.209757 2.81512 1.43299 2.00084 3.48072 1.4015C5.23354 0.886887 8.44219 0.275143 13.7796 0.275143M14.575 23.9823C20.3713 23.9823 23.4346 20.9794 23.4346 15.298C23.4346 12.4625 22.6164 10.2718 21.0048 8.78789C19.3911 7.30193 17.0028 6.54758 13.9083 6.54758C11.709 6.54758 9.5699 6.84932 7.72362 7.41766L7.57824 7.46313V22.9427L7.73608 22.982C10.4006 23.6454 12.6996 23.9823 14.575 23.9823ZM13.7754 0.0664062C4.52535 0.0664062 0 1.9223 0 3.57773V40.7308C0 42.0473 1.92104 42.6921 3.85662 42.6921C5.7922 42.6921 7.788 42.0287 7.788 40.7308V28.9424C10.9157 29.6698 13.5781 30.0026 15.9727 30.0026C26.9548 30.0026 31.1458 22.982 31.1458 15.3662C31.1458 5.4977 24.7575 0.0664062 13.7754 0.0664062ZM14.575 23.7756C12.7121 23.7756 10.4484 23.4449 7.788 22.7815V7.61607C9.71735 7.02086 11.8481 6.75632 13.9104 6.75632C20.0328 6.75632 23.2269 9.7365 23.2269 15.3001C23.2269 20.1341 20.9632 23.7777 14.575 23.7777V23.7756Z" fill="#1A74E2" />
          <path d="M66.549 42.2518H41.4612C40.4623 42.2518 39.9971 40.7947 39.9971 39.3377V3.31303C39.9971 1.85601 40.5287 0.53125 41.4612 0.53125H67.1492C70.2768 0.53125 66.8833 7.35343 63.7557 7.35343H47.783V17.751H61.8907C64.4846 17.751 64.4846 24.5711 61.8907 24.5711H47.7165V35.4337H66.549C69.2114 35.4337 69.2114 42.2538 66.549 42.2538V42.2518Z" fill="#1A74E2" />
          <path d="M67.1492 0.735967C67.5064 0.735967 67.7743 0.835169 67.9425 1.02944C68.1024 1.21338 68.1834 1.48618 68.1834 1.84165C68.1834 2.77167 67.6227 4.09436 66.7525 5.21244C65.7992 6.44006 64.7068 7.14481 63.7557 7.14481H47.5753V17.9557H61.8907C62.5449 17.9557 62.9436 18.4745 63.1659 18.9085C63.4608 19.4892 63.6311 20.3118 63.6311 21.1591C63.6311 22.0065 63.4628 22.829 63.1659 23.4098C62.9457 23.8438 62.5449 24.3625 61.8907 24.3625H47.5089V35.6384H66.549C67.2239 35.6384 67.6351 36.1572 67.8615 36.5932C68.1647 37.174 68.3371 37.9945 68.3371 38.8418C68.3371 39.6892 68.1626 40.5096 67.8615 41.0904C67.6351 41.5265 67.2239 42.0452 66.549 42.0452H41.4612C40.8569 42.0452 40.2048 41.1979 40.2048 39.3378V3.31314C40.2048 2.06486 40.645 0.738034 41.4612 0.738034H67.1492M67.1492 0.529297H41.4612C40.5287 0.529297 39.9971 1.85405 39.9971 3.31108V39.3378C39.9971 40.7928 40.4623 42.2519 41.4612 42.2519H66.549C69.2114 42.2519 69.2114 35.4318 66.549 35.4318H47.7165V24.5692H61.8907C64.4846 24.5692 64.4846 17.7491 61.8907 17.7491H47.783V7.35148H63.7557C66.8833 7.35148 70.2768 0.529297 67.1492 0.529297Z" fill="#1A74E2" />
          <path d="M104.017 42.2518H78.929C77.93 42.2518 77.4648 40.7947 77.4648 39.3377V3.31303C77.4648 1.85601 77.9965 0.53125 78.929 0.53125H104.617C107.745 0.53125 104.349 7.35343 101.223 7.35343H85.2508V17.751H99.3585C101.954 17.751 101.954 24.5711 99.3585 24.5711H85.1822V35.4337H104.017C106.679 35.4337 106.679 42.2538 104.017 42.2538V42.2518Z" fill="#1A74E2" />
          <path d="M104.615 0.735967C104.972 0.735967 105.238 0.835169 105.408 1.02944C105.568 1.21338 105.649 1.48618 105.649 1.83959C105.649 2.7696 105.086 4.09229 104.218 5.21244C103.265 6.44006 102.172 7.14481 101.221 7.14481H85.0409V17.9557H99.3563C100.01 17.9557 100.411 18.4745 100.631 18.9085C100.926 19.4892 101.097 20.3097 101.097 21.1591C101.097 22.0085 100.926 22.829 100.631 23.4098C100.411 23.8438 100.01 24.3625 99.3563 24.3625H84.9724V35.6384H104.015C104.689 35.6384 105.101 36.1572 105.327 36.5932C105.63 37.174 105.803 37.9945 105.803 38.8418C105.803 39.6892 105.628 40.5096 105.327 41.0904C105.101 41.5265 104.689 42.0452 104.015 42.0452H78.9268C78.3224 42.0452 77.6703 41.1979 77.6703 39.3378V3.31314C77.6703 2.06486 78.1106 0.738034 78.9268 0.738034H104.615M104.615 0.529297H78.9268C77.9964 0.529297 77.4626 1.85405 77.4626 3.31108V39.3378C77.4626 40.7928 77.9279 42.2519 78.9268 42.2519H104.015C106.677 42.2519 106.677 35.4318 104.015 35.4318H85.18V24.5692H99.3563C101.952 24.5692 101.952 17.7491 99.3563 17.7491H85.2486V7.35148H101.221C104.349 7.35148 107.742 0.529297 104.615 0.529297Z" fill="#1A74E2" />
          <path d="M141.349 50.3304C139.019 48.3443 137.09 45.2318 135.96 42.3178C134.496 42.5823 132.899 42.7146 131.235 42.7146C118.924 42.7146 111.603 34.7681 111.603 21.3883C111.603 8.00847 118.924 0 131.235 0C143.546 0 151 7.94647 151 21.3904C151 30.2648 147.806 36.6902 142.016 40.0671C143.546 43.0473 146.076 45.6968 148.938 47.8152C151.866 49.8013 143.214 52.1863 141.351 50.3304H141.349ZM131.235 35.8945C138.556 35.8945 142.813 30.5314 142.813 21.3904C142.813 12.2493 138.554 6.82218 131.235 6.82218C123.916 6.82218 119.788 12.1853 119.788 21.3904C119.788 30.5955 123.981 35.8945 131.235 35.8945Z" fill="#1A74E2" />
          <path d="M131.235 0.20667C137.279 0.20667 142.257 2.13904 145.631 5.79296C149.008 9.45103 150.792 14.8431 150.792 21.3904C150.792 30.1015 147.721 36.498 141.912 39.8894L141.744 39.9886L141.833 40.1622C143.268 42.9585 145.683 45.6638 148.821 47.9867C149.436 48.4042 149.338 48.7225 149.305 48.8279C149.008 49.7827 146.171 50.7933 143.785 50.7933C142.705 50.7933 141.893 50.5763 141.499 50.1857L141.492 50.1795L141.486 50.1733C139.378 48.3753 137.384 45.4116 136.155 42.2434L136.093 42.0842L135.924 42.1152C134.473 42.3777 132.896 42.51 131.237 42.51C125.256 42.51 120.317 40.5818 116.957 36.932C113.591 33.276 111.813 27.9025 111.813 21.3924C111.813 14.8823 113.593 9.45929 116.957 5.79503C120.315 2.1411 125.252 0.208737 131.237 0.208737M131.235 36.1011C134.89 36.1011 137.897 34.7516 139.933 32.1992C141.953 29.6634 143.023 25.9268 143.023 21.3924C143.023 16.8581 141.955 13.0719 139.933 10.5278C137.899 7.9692 134.892 6.61758 131.235 6.61758C127.578 6.61758 124.635 7.97127 122.629 10.5298C120.635 13.0698 119.582 16.8271 119.582 21.3945C119.582 25.9619 120.635 29.6696 122.629 32.2013C124.639 34.7557 127.615 36.1053 131.235 36.1053M131.235 0C118.924 0 111.603 7.94647 111.603 21.3904C111.603 34.8343 118.924 42.7167 131.235 42.7167C132.899 42.7167 134.496 42.5823 135.96 42.3199C137.092 45.2339 139.021 48.3464 141.349 50.3325C141.823 50.8037 142.734 51.0021 143.783 51.0021C146.861 51.0021 151.12 49.2991 148.936 47.8173C146.074 45.6989 143.544 43.0494 142.014 40.0692C147.804 36.6922 150.998 30.2668 150.998 21.3924C151 7.94647 143.679 0 131.235 0ZM131.235 35.8945C123.981 35.8945 119.788 30.5314 119.788 21.3904C119.788 12.2493 123.981 6.82218 131.235 6.82218C138.489 6.82218 142.813 12.1853 142.813 21.3904C142.813 30.5955 138.554 35.8945 131.235 35.8945Z" fill="#1A74E2" />
        </g>
        <defs>
          <clipPath id="clip0_1490_7220">
            <rect width="151" height="51" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </Link>
  </div>

);

export default Logo;
