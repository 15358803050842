import React, { useState } from 'react';
import { Button } from '@mui/material';
import CreateTestimonial from '../Modals/CreateTestimonial';

const SettingsHeader = ({
  handleClose = () => {},
  setAlertMessage = () => {},
  setAlertHeaderMessage = () => {},
  setShowAlertMessage = () => {},
  exportDisabled = false,
  exportData = () => {},
}) => {
  const [openTestimonial, setOpenTestimonial] = useState(false);
  return (
    <div className="testimonial-header">
      <h2>Testimonials</h2>
      <div className="action-btns">
        <Button className="btn btn__outlined" disabled={exportDisabled} onClick={exportData}>
          <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.84238 0.423689C8.76713 0.344434 8.664 0.296875 8.55708 0.296875H2.15613C0.974934 0.296875 0 1.26793 0 2.44897V14.5533C0 15.7344 0.974966 16.7055 2.1561 16.7055H10.8439C12.025 16.7055 13 15.7344 13 14.5533V4.94192C13 4.83892 12.9524 4.73989 12.8851 4.66451L8.84238 0.423689ZM8.9573 1.696L11.6644 4.53773H9.90461C9.38142 4.53773 8.9573 4.11758 8.9573 3.59446V1.696ZM10.8439 15.9128H2.1561C1.41499 15.9128 0.792676 15.2985 0.792676 14.5533V2.44897C0.792676 1.70785 1.41098 1.08955 2.1561 1.08955H8.16462V3.59443C8.16462 4.5575 8.94153 5.3304 9.90461 5.3304H12.2073V14.5533C12.2073 15.2985 11.589 15.9128 10.8439 15.9128Z" fill="#1A74E2" />
            <path d="M9.76193 13.1774H3.23814C3.02021 13.1774 2.8418 13.3557 2.8418 13.5738C2.8418 13.7917 3.02018 13.9701 3.23814 13.9701H9.76594C9.98386 13.9701 10.1623 13.7917 10.1623 13.5738C10.1623 13.3557 9.98386 13.1774 9.76193 13.1774ZM6.21072 11.7545C6.2861 11.8338 6.3891 11.8813 6.50002 11.8813C6.61106 11.8813 6.7141 11.8338 6.78935 11.7545L9.11193 9.26153C9.26256 9.1029 9.25064 8.8493 9.09216 8.70271C8.93352 8.55209 8.67992 8.56388 8.53334 8.72248L6.89636 10.4782V6.15024C6.89636 5.93216 6.71798 5.75391 6.50002 5.75391C6.28209 5.75391 6.10368 5.93216 6.10368 6.15024V10.4782L4.47073 8.72248C4.32011 8.56398 4.07039 8.55209 3.91188 8.70271C3.7534 8.8533 3.74151 9.10306 3.89211 9.26153L6.21072 11.7545Z" fill="#1A74E2" />
          </svg>
          <span>Export</span>
        </Button>
        <Button className="btn btn__filled" onClick={() => setOpenTestimonial(true)}>
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0313 8.34766H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.51758 1.96094V15.0372" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span>Add</span>
        </Button>
      </div>
      {openTestimonial && (
      <CreateTestimonial
        openTestimonialModal={openTestimonial}
        handleClose={(actionPerformed = false) => {
          setOpenTestimonial(false);
          if (actionPerformed) {
            handleClose();
          }
        }}
        title="Create"
        existingTestimonial={{}}
        setAlertMessage={setAlertMessage}
        setShowAlertMessage={setShowAlertMessage}
        setAlertHeaderMessage={setAlertHeaderMessage}
      />
      )}

    </div>
  );
};

export default SettingsHeader;
