import { useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { errorsActions } from './store';
import customErrorMessages from './common/util/customErrorMessages';
import customFetch from './common/util/customFetch';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/* eslint-disable */
export const useEffectAsync = (effect, deps) => {
  const dispatch = useDispatch();
  const ref = useRef();
  useEffect(() => {
    effect()
      .then((result) => ref.current = result)
      .catch((error) => {
        customFetch('/api/session').then((response) => {
          if (response.ok){
            const errorMessage = customErrorMessages(error.message);
            dispatch(errorsActions.push(errorMessage));
          }
        });
      });
      
    return () => {
      const result = ref.current;
      if (result) {
        result();
      }
    };
  }, [...deps, dispatch]);
};

export const useCatch = (method) => {
  const dispatch = useDispatch();
  return (...parameters) => {
    return method(...parameters).catch((error) => {
      customFetch('/api/session').then((response) => {
        if (response.ok){
          const errorMessage = customErrorMessages(error.message);
          dispatch(errorsActions.push(errorMessage));
        }
      });
    });
  };
};

export const useCatchCallback = (method, deps) => {
  return useCallback(useCatch(method), deps);
};
